import { Button, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { Component } from "react";
import { connect } from "react-redux";

import * as api from "../../../Api/ApiCallbacks";
import MultiValueDropDown from "../MultiValueDropDown";

export class JailEmailDocModal extends Component {
  render() {
    const {
      id,
      subjectPurpose,
      docs,
      documentTypes,
      showModal,
      closeJailEmailDocModal,
      updateMaintenanceForm,
      formEnabled,
    } = this.props;
    return (
      <div>
        <Modal open={showModal} onClose={() => closeJailEmailDocModal()}>
          <Box className="modal-container">
            <Box className="doc-modal-container-form">
              {id === "" ? (
                <p className="doc-modal-container-para">
                  Enter Details to add new Jail Email Purpose Documents:
                </p>
              ) : (
                <p className="doc-modal-container-para">
                  Enter Details to update Jail Email Purpose Documents:
                </p>
              )}
              <Box className="doc-modal-container-form-2">
                <TextField
                  id="subject-purpose"
                  fullWidth
                  label="Subject Purpose"
                  type="search"
                  name="subjectPurpose"
                  value={subjectPurpose}
                  onChange={(event) =>
                    updateMaintenanceForm(event.target.name, event.target.value)
                  }
                />
              </Box>
              <MultiValueDropDown
                selected={docs}
                options={documentTypes}
                label="documentTypeID"
                textFieldLabel="Document Type"
                page="JailEmailDocumentMaintenanceVariables"
                addType="addEmailPurposeDoc"
                addTypeData="docs"
              />
              <Box className="button-container">
                {id === "" ? (
                  <Button
                    disabled={!formEnabled}
                    onClick={() =>
                      this.props.submitJailEmailDocForm(subjectPurpose, docs)
                    }
                    variant="contained"
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    disabled={!formEnabled}
                    onClick={() =>
                      this.props.updateJailEmailDocForm(
                        id,
                        subjectPurpose,
                        docs
                      )
                    }
                    variant="contained"
                  >
                    Update
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  id: state.JailEmailDocumentMaintenanceVariables.addEmailPurposeDoc.id,
  subjectPurpose:
    state.JailEmailDocumentMaintenanceVariables.addEmailPurposeDoc
      .subjectPurpose,
  docs: state.JailEmailDocumentMaintenanceVariables.addEmailPurposeDoc.docs,
  documentTypes: state.DocumentTypeMaintenanceVariables.documentTypes,
  formEnabled: state.JailEmailDocumentMaintenanceVariables.formEnabled,
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeJailEmailDocModal: () =>
      dispatch({
        type: "CLOSE_JAIL_EMAIL_DOC_MODAL",
      }),
    updateMaintenanceForm: (name, value) =>
      dispatch({
        type: "UPDATE_MAINTENANCE_FORM",
        payload: {
          page: "JailEmailDocumentMaintenanceVariables",
          addType: "addEmailPurposeDoc",
          name,
          value,
        },
      }),
    submitJailEmailDocForm: (subjectPurpose, docs) =>
      api.submitJailEmailDocForm(
        {
          subjectPurpose,
          docs,
        },
        dispatch
      ),
    updateJailEmailDocForm: (id, subjectPurpose, docs) =>
      api.updateJailEmailDocForm(
        {
          id,
          subjectPurpose,
          docs,
        },
        dispatch
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JailEmailDocModal);
