import Papa from "papaparse";
import { toast } from "react-hot-toast";
import JSZip from "jszip";
import axios from "axios";
import { saveAs } from "file-saver";
import fileDownload from "js-file-download";

import moment from "moment";
import "moment-timezone";

import { Amplify, Auth } from "aws-amplify";

let acceptedDocketFiles = [];
let jwtToken = null;
// let logrocketIngestServer = null;
// let logrocketSdkServer = null;
// let logrocketApp = null;

export const convertDate = (str) => {
  return moment.utc(str).format("MM/DD/YYYY");
};

export const convertDatetime = (str) => {
  return moment.utc(str).tz("America/New_York").format("MM/DD/YYYY HH:mm:ss");
};

export const isEmpty = (obj) => {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return true;
};

export const isValidEmail = (email) => {
  return /\S+@\S+\.\S+/.test(email);
};

export const isValidPassword = (password) => {
  const uppercaseRegExp = /(?=.*?[A-Z])/;
  const lowercaseRegExp = /(?=.*?[a-z])/;
  const digitsRegExp = /(?=.*?[0-9])/;
  const specialCharRegExp = /[!@#$%^&*()_+\-=[\]{};'~`:"\\|,.<>/?]/;
  const minLengthRegExp = /.{8,}/;
  const passwordLength = password.length;
  const uppercasePassword = uppercaseRegExp.test(password);
  const lowercasePassword = lowercaseRegExp.test(password);
  const digitsPassword = digitsRegExp.test(password);
  const specialCharPassword = specialCharRegExp.test(password);
  const minLengthPassword = minLengthRegExp.test(password);
  let errMsg = null;
  if (passwordLength === 0) {
    errMsg = "Password is empty";
  } else if (!uppercasePassword) {
    errMsg = "At least one Uppercase";
  } else if (!lowercasePassword) {
    errMsg = "At least one Lowercase";
  } else if (!digitsPassword) {
    errMsg = "At least one digit";
  } else if (!specialCharPassword) {
    errMsg = "At least one Special Characters";
  } else if (!minLengthPassword) {
    errMsg = "At least minumum 8 characters";
  } else {
    errMsg = null;
  }
  return errMsg;
};

export const getDefendantName = (firstName, lastName, middleName) => {
  if (!firstName) {
    firstName = "";
  }
  if (!middleName) {
    middleName = "";
  }
  if (!lastName) {
    lastName = "";
  }
  let nameArray = [firstName, middleName, lastName];
  nameArray = nameArray.filter(Boolean);
  return nameArray.join(" ");
};

function decodeJwt(token) {
  var base64Payload = token.split(".")[1];
  var payload = decodeURIComponent(
    window
      .atob(base64Payload)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(payload);
}

const customAxios = axios.create({
  baseURL: "",
});

const updateJwtToken = (token) => {
  customAxios.defaults.headers.common.Authorization = "Bearer " + token;
};

export const clearJwtToken = () => {
  delete customAxios.defaults.headers.common.Authorization;
  jwtToken = null;
};

export const logout = async (dispatch) => {
  await Auth.signOut();
  clearJwtToken();
  dispatch({ type: "LOG_OUT" });
};

export const loadApiSetting = (dispatch) => {
  axios.get("/config.json").then((res) => {
    const payload = {
      apiEndpoint: res.data.backend.apiEndpoint,
      userPoolId: res.data.backend.userPoolId,
      userPoolWebClientId: res.data.backend.userPoolWebClientId,
      region: res.data.backend.region,
    };
    customAxios.defaults.baseURL = res.data.backend.apiEndpoint;

    Amplify.configure({
      Auth: {
        region: res.data.backend.region,
        userPoolId: res.data.backend.userPoolId,
        userPoolWebClientId: res.data.backend.userPoolWebClientId,
      },
    });
    dispatch({ type: "LOAD_API_SETTING", payload: payload });
  });
};

export const loadDocTypeData = (dispatch) => {
  customAxios
    .get(`/document_type_maintenance/get_document_type`)
    .then((res) => {
      dispatch({ type: "LOAD_DOC_TYPE_DATA", payload: res.data });
    });
};

export const loadSignPartyData = (dispatch) => {
  customAxios
    .get(`/signature_party_maintenance/get_signature_party`)
    .then((res) => {
      dispatch({ type: "LOAD_SIGN_PARTY_DATA", payload: res.data });
    });
};

export const loadDocSignData = (dispatch) => {
  customAxios
    .get(`/signature_template_maintenance/get_doc_signature_template`)
    .then((res) => {
      dispatch({ type: "LOAD_DOC_SIGN_DATA", payload: res.data });
    });
};

export const loadDocSignCoordData = (dispatch) => {
  customAxios
    .get(
      `/signature_template_maintenance/get_doc_signature_template_mapped_by_doc_type`
    )
    .then((res) => {
      dispatch({ type: "LOAD_DOC_SIGN_COORD_DATA", payload: res.data });
    });
};

export const loadJailEmailData = (dispatch) => {
  customAxios
    .get(`/jail_email_maintenance/get_jail_email_purpose`)
    .then((res) => {
      dispatch({ type: "LOAD_JAIL_EMAIL_DATA", payload: res.data });
    });
};

export const loadJailEmailDocData = (dispatch) => {
  customAxios.get(`/purpose_doc_maintenance/get_purpose_doc`).then((res) => {
    dispatch({ type: "LOAD_JAIL_EMAIL_DOC_DATA", payload: res.data });
  });
};

export const userLogin = (user, username, dispatch) => {
  jwtToken = user.signInUserSession.accessToken.jwtToken;
  updateJwtToken(jwtToken);
  let userData = decodeJwt(jwtToken);
  let userGroup = userData["cognito:groups"][0];
  if (userGroup === "admin" || userGroup === "admins") {
    loadDocTypeData(dispatch);
    loadSignPartyData(dispatch);
    loadDocSignCoordData(dispatch);
    loadJailEmailData(dispatch);
    loadJailEmailDocData(dispatch);
  } else {
    loadJailEmailDocData(dispatch);
  }

  dispatch({
    type: "USER_LOGIN",
    payload: { userGroup, username, jwtToken },
  });
};

export const forceChangePassword = (
  username,
  password,
  newPassword,
  confirmNewPassword,
  dispatch
) => {
  if (username === "") {
    toast.error("Email cannot be empty", { id: 1 });
    return;
  }
  let errMsg = isValidPassword(newPassword.trim());
  if (errMsg !== null) {
    toast.error(errMsg, { id: 1 });
    return;
  }
  if (confirmNewPassword.trim() !== newPassword.trim()) {
    toast.error("Confirm password should be same as the new password", {
      id: 1,
    });
    return;
  }
  toast.loading("Updating password", { id: 1 });

  Auth.signIn(username.trim(), password.trim())
    .then((user) => {
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        Auth.completeNewPassword(user, newPassword)
          .then((user) => {
            userLogin(user, username, dispatch);
            toast.success("Password updated sucessfully", { id: 1 });
          })
          .catch((err) => {
            const { code, message } = err;
            switch (code) {
              case "CodeMismatchException":
                toast.error(message, { id: 1 });
                break;
              case "UserNotFoundException":
                toast.error(message, { id: 1 });
                break;
              case "NetworkError":
                toast.error(message, { id: 1 });
                break;
              default:
                toast.error("Password updation failed, Please try again");
                break;
            }
          });
      } else {
        userLogin(user, username, dispatch);
      }
    })
    .catch((err) => {
      toast.error("Password updation failed, Please try again", { id: 1 });
    });
};

export const setNewPassword = (username, newPassword, code, dispatch) => {
  let errMsg = isValidPassword(newPassword.trim());
  if (errMsg !== null) {
    toast.error(errMsg, { id: 1 });
    return;
  }
  toast.loading("Updating password", { id: 1 });

  // Collect confirmation code and new password, then
  Auth.forgotPasswordSubmit(username.trim(), code.trim(), newPassword.trim())
    .then((data) => {
      toast.success("Password updated successfully", { id: 1 });
      dispatch({ type: "PASSWORD_UPDATED" });
    })
    .catch((err) => {
      const { code, message } = err;
      switch (code) {
        case "CodeMismatchException":
          toast.error(message, { id: 1 });
          break;
        case "NetworkError":
          toast.error(message, { id: 1 });
          break;
        default:
          toast.error("Password updation failed, Please try again");
          break;
      }
    });
};

export const getCode = (username, dispatch) => {
  if (!isValidEmail(username.trim())) {
    toast.error("Enter a valid email", { id: 1 });
    return;
  }

  // Send confirmation code to user's email
  Auth.forgotPassword(username.trim())
    .then((data) => {
      toast.success("Code generated, check your mail");
      dispatch({ type: "GET_CODE_INITIATED" });
    })
    .catch((err) => {
      const { code, message } = err;
      switch (code) {
        case "CodeMismatchException":
          toast.error(message, { id: 1 });
          break;
        case "UserNotFoundException":
          toast.error(message, { id: 1 });
          break;
        case "NetworkError":
          toast.error(message, { id: 1 });
          break;
        default:
          toast.error("Password updation failed, Please try again");
          break;
      }
    });
};

export const cognitoSignUp = (username, email, password) => {
  Auth.signUp({
    username: username.trim(),
    password: password.trim(),
    attributes: {
      email: email.trim(),
      "custom:group": "admin",
    },
    autoSignIn: {
      enabled: true,
    },
  })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const cognitoSignIn = (email, password, dispatch) => {
  email = email.trim();
  password = password.trim();
  if (email === "") {
    toast.error("Please enter email ID", { id: 1 });
    return;
  }
  if (password === "") {
    toast.error("Password cannot be empty", { id: 1 });
    return;
  }
  Auth.signIn(email, password)
    .then((user) => {
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        toast.success("Please reset your password", { id: 1 });
        dispatch({
          type: "FORCE_CHANGE_PASSWORD",
        });
      } else {
        userLogin(user, email, dispatch);
      }
    })
    .catch((err) => {
      const { code, message } = err;
      switch (code) {
        case "NotAuthorizedException":
          toast.error(message, { id: 1 });
          break;
        case "NetworkError":
          toast.error(message, { id: 1 });
          break;
        case "InvalidPasswordException":
          toast.error(message, { id: 1 });
          break;
        default:
          toast.error("Invalid credentials, Please try again");
          break;
      }
    });
};

export const handleMultipleFileDrop = (accepted, rejected, dispatch) => {
  accepted.map((file) =>
    Papa.parse(file, {
      header: true,
      keepEmptyRows: false,
      skipEmptyLines: true,
      complete: function (results) {
        acceptedDocketFiles.push(file);
        dispatch({
          type: "HANDLE_FILE_DROP",
          payload: {
            name: file.path,
            data: results.data,
            headers: results.meta.fields,
          },
        });
      },
    })
  );
  if (rejected.length > 0) {
    toast.error("File format not supported", { id: 1 });
  }
};

export const handleSingleFileDrop = (accepted, rejected, dispatch) => {
  if (accepted.length > 0) {
    const file = accepted[0];
    const fileUrl = URL.createObjectURL(file);
    dispatch({
      type: "HANDLE_DOC_FILE_DROP",
      payload: fileUrl,
    });
  }
  if (rejected.length > 0) {
    toast.error("File format not supported", { id: 1 });
  }
};

export const deleteFile = (filePath, dispatch) => {
  const index = acceptedDocketFiles.findIndex((file) => file.path === filePath);
  if (index > -1) {
    acceptedDocketFiles.splice(index, 1);
  } else {
    toast.error("File not found", { id: 1 });
    return;
  }

  dispatch({
    type: "DELETE_FILE",
    payload: { filePath },
  });
};

export const docketUpload = async (parsedData, dispatch) => {
  if (acceptedDocketFiles.length === 0) {
    toast.error("No files to upload", { id: 1 });
    return;
  }
  const jszip = JSZip();
  acceptedDocketFiles.forEach((file) => {
    const reader = new FileReader();
    let arrayBuffer;
    reader.onload = () => {
      arrayBuffer = reader.result;
      jszip.file(file.name, arrayBuffer);
    };
    reader.readAsArrayBuffer(file);
  });
  toast.loading("Uploading docket", { id: 1 });

  setTimeout(() => {
    jszip
      .generateAsync({
        type: "blob",
        compression: "deflate",
      })
      .then(async (content) => {
        const formData = new FormData();
        formData.append("upload_config", JSON.stringify({ files: parsedData }));
        formData.append("zip_file", content);
        try {
          await customAxios.post(`/docket/docket_upload`, formData);
          toast.success("Docket uploaded successfully", { id: 1 });
          dispatch({ type: "UPLOAD_DOCKET" });
        } catch (error) {
          toast.error("Something went wrong, please try again", { id: 1 });
        }
      });
  }, 1000);
};

export const submitDocketForm = (data, dispatch) => {
  const { caseNbr, firstName, middleName, lastName, startDate, endDate } = data;
  let start_date =
    startDate === null || startDate === "" ? "" : startDate + " 00:00:00";
  let end_date =
    endDate === null || endDate === "" ? "" : endDate + " 23:59:59";
  toast.loading("Searching for docket history", { id: 1 });

  dispatch({ type: "TOGGLE_CIRCULAR_LOADING" });
  customAxios
    .get(
      `/docket/docket_history?case_nbr=${caseNbr.trim()}&start_docket_date=${start_date}&end_docket_date=${end_date}&first_name=${firstName.trim()}&middle_name=${middleName.trim()}&last_name=${lastName.trim()}`
    )
    .then((res) => {
      toast.success("Docket history fetched", { id: 1 });
      dispatch({ type: "SUBMIT_DOCKET_FORM", payload: res.data });
    })
    .catch((err) => {
      toast.error("Error in searching, Please try again", { id: 1 });
      dispatch({ type: "STOP_LOADING" });
    });
};

export const downloadDocket = (id, documentName) => {
  customAxios
    .get(`/docket/docket_download?docket_id=${id}`)
    .then((res) => {
      if (isSafari()) {
        downloadInSafari(res.data.download_link, documentName, "csv");
      } else {
        saveAs(res.data.download_link, "filename.csv");
      }
    })
    .catch((err) => {
      toast.error("File download failed, Please try again", { id: 1 });
    });
};

export const isSafari = () => {
  console.log(navigator.userAgent);
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const downloadInSafari = async (signed_url, documentName, type) => {
  axios
    .get(signed_url, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, documentName);
    })
    .catch((err) => {
      toast.error("File download failed!!!");
    });
  // const url = window.URL.createObjectURL(file.data);
  // const link = document.createElement("a");
  // link.href = url;
  // link.setAttribute("download", `${documentName}.${type}`);
  // document.body.appendChild(link);
  // link.click();
  // document.body.removeChild(link);
  // window.URL.revokeObjectURL(url);
};

export const downloadSignaturePDF = (id, documentName) => {
  customAxios
    .get(`/signature_documents/download?document_details_id=${id}`)
    .then((res) => {
      if (isSafari()) {
        downloadInSafari(res.data.download_link, documentName, "pdf");
      } else {
        saveAs(res.data.download_link, "filename.pdf");
      }
    })
    .catch((err) => {
      console.log(err);
      toast.error("File download failed, Please try again", { id: 1 });
    });
};

export const downloadApplication = (id) => {
  customAxios
    .get(`/download_application/download?version_id=${id}`)
    .then((res) => {
      saveAs(res.data.download_link, "filename.apk");
    })
    .catch((err) => {
      toast.error("File download failed, Please try again", { id: 1 });
    });
};

export const submitSignHistoryForm = (data, dispatch) => {
  const { caseNbr, firstName, middleName, lastName, startDate, endDate } = data;
  let start_date =
    startDate === null || startDate === "" ? "" : startDate + " 00:00:00";
  let end_date =
    endDate === null || endDate === "" ? "" : endDate + " 23:59:59";
  toast.loading("Searching for signature history", { id: 1 });
  dispatch({ type: "TOGGLE_CIRCULAR_LOADING" });
  customAxios
    .get(
      `/signature_documents/history?case_nbr=${caseNbr.trim()}&start_docket_date=${start_date}&end_docket_date=${end_date}&first_name=${firstName.trim()}&middle_name=${middleName.trim()}&last_name=${lastName.trim()}`
    )
    .then((res) => {
      toast.success("Signature history fetched", { id: 1 });
      dispatch({ type: "SUBMIT_SIGN_HISTORY_FORM", payload: res.data });
    })
    .catch((err) => {
      toast.error("Something went wrong, Please try again", { id: 1 });
      dispatch({ type: "STOP_LOADING" });
    });
};

export const submitJailEmailHistoryForm = (data, dispatch) => {
  const { caseNbr, emailPurpose, startDate, endDate } = data;
  let formData = {
    purpose: emailPurpose === null ? "" : emailPurpose.subjectPurpose,
    case_nbr: caseNbr.trim(),
    start_date:
      startDate === null || startDate === "" ? "" : startDate + " 00:00:00",
    end_date: endDate === null || endDate === "" ? "" : endDate + " 23:59:59",
  };
  toast.loading("Searching email history", { id: 1 });
  dispatch({ type: "TOGGLE_CIRCULAR_LOADING" });
  customAxios
    .post(`/emails/filter_email_history`, formData)
    .then((res) => {
      toast.success("Email history fetched", { id: 1 });
      dispatch({ type: "SUBMIT_JAIL_EMAIL_HISTORY_FORM", payload: res.data });
    })
    .catch((err) => {
      toast.error("Something went wrong, Please try again", { id: 1 });
      dispatch({ type: "STOP_LOADING" });
    });
};

export const resendJailEmail = (id, dispatch) => {
  toast.success("Email re-sent", { id: 1 });
  customAxios
    .post(`/emails/resend_email?email_history_id=${id}`)
    .then((res) => {
      dispatch({ type: "RESEND_JAIL_EMAIL", payload: id });
    })
    .catch((err) => {
      toast.error("Error in re-sending the mail, Please try again", { id: 1 });
    });
};

export const submitDocTypeForm = (data, dispatch) => {
  const { documentTypeID, documentTypeName, documentTypeDesc } = data;
  let formData = {
    document_type_id: documentTypeID.trim(),
    document_type_name: documentTypeName.trim(),
    document_type_desc: documentTypeDesc.trim(),
  };
  customAxios
    .post(`/document_type_maintenance/insert_document_type`, formData)
    .then((res) => {
      loadDocTypeData(dispatch);
      loadDocSignCoordData(dispatch);
      toast.success("Document Type Inserted", { id: 1 });
      dispatch({ type: "SUBMIT_DOCUMENT_TYPE", payload: res.data });
    })
    .catch((err) => {
      toast.error("Error submitting the data, Please try again", { id: 1 });
    });
};

export const submitDocSignForm = (data, dispatch) => {
  const {
    documentTypeID,
    signaturePartyID,
    x0,
    y0,
    x1,
    y1,
    pageNo,
    isRequired,
  } = data;
  let formData = {
    document_type_id: documentTypeID,
    signature_party_id: signaturePartyID,
    is_required: isRequired,
    page_no: pageNo,
    x0,
    y0,
    x1,
    y1,
    signature_hint_text: "",
  };
  customAxios
    .post(
      `/signature_template_maintenance/insert_doc_signature_template`,
      formData
    )
    .then((res) => {
      loadDocSignData(dispatch);
      toast.success("Document signature party inserted", { id: 1 });
      dispatch({ type: "SUBMIT_DOCUMENT_SIGN", payload: res.data });
    })
    .catch((err) => {
      toast.error("Error submitting the data, Please try again", { id: 1 });
    });
};

export const submitSignPartyForm = (data, dispatch) => {
  const { signaturePartyName } = data;
  let formData = {
    signature_party_name: signaturePartyName.trim(),
  };
  customAxios
    .post(`/signature_party_maintenance/insert_signature_party`, formData)
    .then((res) => {
      loadSignPartyData(dispatch);
      loadDocSignCoordData(dispatch);
      toast.success("Signature Party Inserted", { id: 1 });
      dispatch({ type: "SUBMIT_SIGNATURE_PARTY", payload: res.data });
    })
    .catch((err) => {
      toast.error("Error submitting the data, Please try again", { id: 1 });
    });
};

export const submitJailEmailForm = (data, dispatch) => {
  const { emailID, subjectPurpose, emailSubject, emailBody } = data;
  if (!isValidEmail(emailID.trim())) {
    toast.error("Enter a valid email", { id: 1 });
    return;
  }
  let formData = {
    email_id: emailID.trim(),
    subject_purpose_id: subjectPurpose.id,
    email_subject: emailSubject.trim(),
    email_body: emailBody.trim(),
  };
  customAxios
    .post(`/jail_email_maintenance/insert_jail_email_purpose`, formData)
    .then((res) => {
      loadJailEmailData(dispatch);
      toast.success("Jail Email Purpose Inserted", { id: 1 });
      dispatch({ type: "SUBMIT_JAIL_EMAIL_PURPOSE", payload: res.data });
    })
    .catch((err) => {
      toast.error("Error submitting the data, Please try again", { id: 1 });
    });
};

export const submitJailEmailDocForm = (data, dispatch) => {
  const { subjectPurpose, docs } = data;
  let doc_ids = docs.map(function (key) {
    return key.id;
  });
  let formData = {
    subject_purpose: subjectPurpose.trim(),
    doc_ids: doc_ids,
  };
  customAxios
    .post(`/purpose_doc_maintenance/insert_purpose_doc`, formData)
    .then((res) => {
      loadJailEmailDocData(dispatch);
      toast.success("Email Purpose Documents Inserted", { id: 1 });
      dispatch({ type: "SUBMIT_JAIL_EMAIL_DOC", payload: res.data });
    })
    .catch((err) => {
      toast.error("Error submitting the data, Please try again", { id: 1 });
    });
};

export const updateDocTypeForm = (data, dispatch) => {
  const { id, documentTypeID, documentTypeName, documentTypeDesc } = data;
  let formData = {
    document_type_id: documentTypeID.trim(),
    document_type_name: documentTypeName.trim(),
    document_type_desc: documentTypeDesc.trim(),
  };
  customAxios
    .patch(`/document_type_maintenance/update_document_type/${id}`, formData)
    .then((res) => {
      loadDocTypeData(dispatch);
      loadDocSignCoordData(dispatch);
      toast.success("Document type updated successfully", { id: 1 });
      dispatch({ type: "SUBMIT_DOCUMENT_TYPE", payload: res.data });
    })
    .catch((err) => {
      toast.error("Error submitting the data, Please try again", { id: 1 });
    });
};

export const updateJailEmailForm = (data, dispatch) => {
  const { id, emailID, subjectPurpose, emailSubject, emailBody } = data;
  let formData = {
    email_id: emailID.trim(),
    subject_purpose_id: subjectPurpose.id,
    email_subject: emailSubject.trim(),
    email_body: emailBody.trim(),
  };
  customAxios
    .patch(`/jail_email_maintenance/update_jail_email_purpose/${id}`, formData)
    .then((res) => {
      loadJailEmailData(dispatch);
      toast.success("Jail Email Purpose updated successfully", { id: 1 });
      dispatch({ type: "SUBMIT_JAIL_EMAIL_PURPOSE", payload: res.data });
    })
    .catch((err) => {
      toast.error("Error submitting the data, Please try again", { id: 1 });
    });
};

export const updateJailEmailDocForm = (data, dispatch) => {
  const { id, subjectPurpose, docs } = data;
  let doc_ids = docs.map(function (key) {
    return key.id;
  });
  let formData = {
    subject_purpose: subjectPurpose.trim(),
    doc_ids: doc_ids,
  };
  customAxios
    .patch(`/purpose_doc_maintenance/update_purpose_doc/${id}`, formData)
    .then((res) => {
      loadJailEmailDocData(dispatch);
      toast.success("Jail Email Purpose documents updated successfully", {
        id: 1,
      });
      dispatch({ type: "SUBMIT_JAIL_EMAIL_DOC", payload: res.data });
    })
    .catch((err) => {
      toast.error("Error submitting the data, Please try again", { id: 1 });
    });
};

export const updateSignPartyForm = (data, dispatch) => {
  const { id, signaturePartyName } = data;
  let formData = {
    signature_party_name: signaturePartyName.trim(),
  };
  customAxios
    .patch(
      `/signature_party_maintenance/update_signature_party/${id}`,
      formData
    )
    .then((res) => {
      loadSignPartyData(dispatch);
      loadDocSignCoordData(dispatch);
      toast.success("Signature party details updated successfully", { id: 1 });
      dispatch({ type: "SUBMIT_SIGNATURE_PARTY", payload: res.data });
    })
    .catch((err) => {
      toast.error("Error submitting the data, Please try again", { id: 1 });
    });
};

export const updateSignCoordinates = (data, dispatch) => {
  const { documentType, coordinates, deletedIDs } = data;
  let formData = {
    documentID: documentType?.id,
    documentType: documentType?.documentTypeID,
    coordinates,
    deletedIDs,
  };
  customAxios
    .put(
      `/signature_template_maintenance/update_mapped_doc_signature_template`,
      formData
    )
    .then((res) => {
      loadDocSignCoordData(dispatch);
      toast.success("Document signature coordinates updated successfully", {
        id: 1,
      });
      dispatch({ type: "SUBMIT_DOCUMENT_SIGN", payload: res.data });
    })
    .catch((err) => {
      toast.error("Error submitting the data, Please try again", { id: 1 });
    });
};

export const updateDocSignForm = (data, dispatch) => {
  const {
    id,
    documentTypeID,
    signaturePartyID,
    x0,
    y0,
    x1,
    y1,
    pageNo,
    isRequired,
  } = data;
  let formData = {
    document_type_id: documentTypeID,
    signature_party_id: signaturePartyID,
    is_required: isRequired,
    page_no: pageNo,
    x0,
    x1,
    y0,
    y1,
    signature_hint_text: "",
  };
  customAxios
    .patch(
      `/signature_template_maintenance/update_doc_signature_template/${id}`,
      formData
    )
    .then((res) => {
      loadDocSignData(dispatch);
      toast.success("Document signature party details updated successfully", {
        id: 1,
      });
      dispatch({ type: "SUBMIT_DOCUMENT_SIGN", payload: res.data });
    })
    .catch((err) => {
      toast.error("Error submitting the data, Please try again", { id: 1 });
    });
};

export const deleteDocType = (id, dispatch) => {
  customAxios
    .delete(`/document_type_maintenance/delete_document_type/${id}`)
    .then((res) => {
      loadDocTypeData(dispatch);
      toast.success("Document type deleted", { id: 1 });
    })
    .catch((err) => {
      toast.error("Error deleting the data, Please try again", { id: 1 });
    });
};

export const deleteSignParty = (id, dispatch) => {
  customAxios
    .delete(`/signature_party_maintenance/delete_signature_party/${id}`)
    .then((res) => {
      loadSignPartyData(dispatch);
      toast.success("Signature party deleted", { id: 1 });
    })
    .catch((err) => {
      toast.error("Error deleting the data, Please try again", { id: 1 });
    });
};

export const deleteDocSign = (id, dispatch) => {
  customAxios
    .delete(
      `/signature_template_maintenance/delete_doc_signature_template/${id}`
    )
    .then((res) => {
      loadDocSignData(dispatch);
      toast.success("Document Signature template deleted", { id: 1 });
    })
    .catch((err) => {
      toast.error("Error deleting the data, Please try again", { id: 1 });
    });
};

export const deleteJailEmail = (id, dispatch) => {
  customAxios
    .delete(`/jail_email_maintenance/delete_jail_email_purpose/${id}`)
    .then((res) => {
      loadJailEmailData(dispatch);
      toast.success("Jail email purpose deleted", { id: 1 });
    })
    .catch((err) => {
      toast.error("Error deleting the data, Please try again", { id: 1 });
    });
};

export const deleteJailEmailDoc = (id, dispatch) => {
  customAxios
    .delete(`/purpose_doc_maintenance/delete_purpose_doc/${id}`)
    .then((res) => {
      loadJailEmailDocData(dispatch);
      toast.success("Jail email purpose document deleted", { id: 1 });
    })
    .catch((err) => {
      toast.error("Error deleting the data, Please try again", { id: 1 });
    });
};
