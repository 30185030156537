import { Box } from "@mui/system";
import React, { Component } from "react";
import { connect } from "react-redux";

class User extends Component {
  render() {
    return (
      <Box className="user">
        <h3 className="username">{this.props.username}</h3>
        <h3 className="userrank">{this.props.userGroup}</h3>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  username: state.UserDataVariables.username,
  userGroup: state.UserDataVariables.userGroup,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
