import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Modal,
  Box,
  Typography,
} from "@mui/material";

export class DocketModal extends Component {
  render() {
    const { showModal, modalKey, modalDetails, closeModal } = this.props;
    return (
      <>
        <Modal open={showModal} onClose={() => closeModal()}>
          <Box className="docket-modal-container">
            <Box sx={{ display: "flex" }}>
              <Typography>{modalKey}</Typography>
            </Box>
            <br />
            <Box
              sx={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Paper sx={{ width: "90%" }}>
                <TableContainer sx={{ maxHeight: 400 }}>
                  <Table
                    stickyHeader
                    aria-label="customized table"
                    className="table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>S. No.</TableCell>
                        <TableCell>Degree Code</TableCell>
                        <TableCell>Start Time</TableCell>
                        <TableCell>Hearing Type Description</TableCell>
                        <TableCell>Style</TableCell>
                        <TableCell>Case No.</TableCell>
                        <TableCell>Charge Offense Desc</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {showModal &&
                        modalDetails[modalKey].map((row, index) => {
                          return (
                            <TableRow tabIndex={-1} key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{row.DegreeCode}</TableCell>
                              <TableCell>{row.StartTime}</TableCell>
                              <TableCell>
                                {row.HearingTypeDescription1}
                              </TableCell>
                              <TableCell>{row.Style}</TableCell>
                              <TableCell>{row.CaseNbr}</TableCell>
                              <TableCell>
                                {row.ChargeOffenseDescription}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </Box>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  modalKey: state.ModalDetailsPageVariables.modalKey,
  modalDetails: state.ModalDetailsPageVariables.modalDetails,
  showModal: state.ModalDetailsPageVariables.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () =>
      dispatch({
        type: "CLOSE_MODAL",
        payload: { page: "DocketUploaderPageVariables" },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocketModal);
