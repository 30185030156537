import React, { Component } from "react";
import { connect } from "react-redux";

import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuRounded from "@mui/icons-material/MenuRounded";

import * as api from "../../Api/ApiCallbacks";

const drawerWidth = 330;

class Header extends Component {
  render() {
    const { open } = this.props;
    return (
      <div className="header">
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            transition: (theme) =>
              theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            ...(open && {
              marginLeft: drawerWidth,
              width: `calc(100% - ${drawerWidth}px)`,
              transition: (theme) =>
                theme.transitions.create(["width", "margin"], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
            }),
          }}
          style={{ background: "#FFFFFF", height: "70px" }}
        >
          <Toolbar sx={{ color: "white" }}>
            <IconButton
              onClick={() => {
                this.props.toggleLeftSideBar(true);
              }}
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{
                marginLeft: 0,
                marginTop: 0,
                marginRight: 2,
                zIndex: 100000,
                "& svg": {
                  fontSize: 36,
                  color: "#162B42",
                },
                ...(open && {
                  display: "none",
                }),
              }}
            >
              <MenuRounded />
            </IconButton>
            <Typography
              variant="h6"
              color="inherit"
              sx={{
                color: "#000000",
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "20px",
                flexGrow: 1,
              }}
              noWrap
            >
              Akron City Integration
            </Typography>
            <Button variant="contained" onClick={() => this.props.logout()}>
              Logout
            </Button>
            <IconButton
              onClick={() => {
                this.props.toggleLeftSideBar(false);
              }}
              sx={{
                position: "absolute",
                marginLeft: -12,
                marginTop: 3,
                zIndex: (theme) => theme.zIndex.drawer + 2,
                "& hover": {
                  color: "#fafafa",
                },
                "& svg": {
                  fontSize: 40,
                  color: "#ffffff",
                  opacity: "70%",
                },
                ...(!open && {
                  display: "none",
                }),
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
            <Divider />
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.LeftSideBarVariables.open,
});

const mapDispatchToProps = (dispatch) => {
  return {
    toggleLeftSideBar: () => dispatch({ type: "TOGGLE_LEFT_SIDE_BAR" }),
    logout: () => api.logout(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
