import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  TablePagination,
} from "@mui/material";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";

import * as api from "../../Api/ApiCallbacks";

export class AppDownloader extends Component {
  render() {
    let i = 0;
    const {
      page,
      rowsPerPage,
      downloadApplication,
      appDownloadVersionData,
      handleChangeRowsPerPage,
      handleChangePage,
    } = this.props;
    return (
      <div className="page-container">
        <Paper>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table
              stickyHeader
              aria-label="app-history-table"
              className="table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>S. No.</TableCell>
                  <TableCell>Version</TableCell>
                  <TableCell>Released on</TableCell>
                  <TableCell>iOS</TableCell>
                  <TableCell>Android</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {appDownloadVersionData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((appVersion) => {
                    i = i + 1;
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                        <TableCell>{appVersion.id}</TableCell>
                        <TableCell>{appVersion.versionNo}</TableCell>
                        <TableCell>
                          {api.convertDate(appVersion.releasedOn)}
                        </TableCell>
                        <TableCell>
                          <AppleIcon
                            color="primary"
                            onClick={(event) => {
                              event.stopPropagation();
                              downloadApplication(document.id, "IOS");
                            }}
                          >
                            <DownloadRoundedIcon />
                          </AppleIcon>
                        </TableCell>
                        <TableCell>
                          <AndroidIcon
                            color="success"
                            onClick={(event) => {
                              event.stopPropagation();
                              downloadApplication(document.id, "ANDROID");
                            }}
                          >
                            <DownloadRoundedIcon />
                          </AndroidIcon>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={appDownloadVersionData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => handleChangePage(event, newPage)}
            onRowsPerPageChange={(event) =>
              handleChangeRowsPerPage(event.target.value)
            }
          />
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  page: state.AppDownloaderPageVariables.page,
  rowsPerPage: state.AppDownloaderPageVariables.rowsPerPage,
  appDownloadVersionData:
    state.AppDownloaderPageVariables.appDownloadVersionData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleChangePage: (event, newPageNumber) => {
      dispatch({
        type: "HANDLE_CHANGE_PAGE",
        payload: {
          page: "AppDownloaderPageVariables",
          event,
          newPageNumber,
        },
      });
    },
    handleChangeRowsPerPage: (val) =>
      dispatch({
        type: "HANDLE_CHANGE_ROWS_PER_PAGE",
        payload: { page: "AppDownloaderPageVariables", data: val },
      }),
    downloadApplication: (val, type) => api.downloadApplication(val, type),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppDownloader);
