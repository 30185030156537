import React, { Component } from "react";
import logo from "../../Images/akron-logo.png";

export default class Logo extends Component {
  render() {
    const { open } = this.props;
    return (
      <img
        src={logo}
        alt="Logo"
        className="Logo"
        style={{ display: open ? "" : "none" }}
      />
    );
  }
}
