import {
  Box,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, { Component } from "react";
import { connect } from "react-redux";
import Logo from "./Logo";
import User from "./User";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import EmailIcon from "@mui/icons-material/Email";
// import HelpCenterRoundedIcon from "@mui/icons-material/HelpCenterRounded";
import TopicIcon from "@mui/icons-material/Topic";
import DriveFolderUploadRoundedIcon from "@mui/icons-material/DriveFolderUploadRounded";
// import DownloadIcon from "@mui/icons-material/Download";
import SettingsIcon from "@mui/icons-material/Settings";
import PlagiarismIcon from "@mui/icons-material/Plagiarism";
import { Link } from "react-router-dom";

const drawerWidth = 330;

const listItemsMapper = {
  Home: {
    listIcon: <HomeRoundedIcon />,
    listText: "Home",
    to: "/home",
    listItems: [],
  },
  "Docket Upload": {
    listIcon: <DriveFolderUploadRoundedIcon />,
    listText: "Docket Upload",
    to: "/docket_uploader",
    listItems: [],
  },
  "Docket History": {
    listIcon: <TopicIcon />,
    listText: "Docket History",
    to: "/docket_history",
    listItems: [],
  },
  "Document Maintenance": {
    listIcon: <SettingsIcon />,
    listText: "Document Maintenance",
    to: "/admin/maintenance",
    listItems: [],
  },
  "Signature Maintenance": {
    listIcon: <PlagiarismIcon />,
    listText: "Signature Maintenance",
    to: "/admin/sign_maintenance",
    listItems: [],
  },
  "e-Signature": {
    listIcon: <NoteAltIcon />,
    listText: "e-Signature",
    to: "/e-signature",
    listItems: [],
  },
  "Jail Email": {
    listIcon: <EmailIcon />,
    listText: "Jail Email",
    to: "/jail_email_history",
    listItems: [],
  },
  // {
  //   listIcon: <DownloadIcon />,
  //   listText: "App Download",
  //   to: "/admin/app_download",
  //   listItems: [],
  // },
  // {
  //   listIcon: <HelpCenterRoundedIcon />,
  //   listText: "Help",
  //   to: "/admin/help",
  //   listItems: [],
  // },
};
class LeftSidebar extends Component {
  render() {
    const { open, listItems } = this.props;
    const sideList = () => (
      <Box component="div" onClick={this.handleClick}>
        <Logo open={open} />
        <Divider />
        <User />
        <List sx={{ marginTop: "2rem" }}>
          {listItems.map((listItem, index) => (
            <Tooltip
              key={index}
              title={listItemsMapper[listItem]?.listText}
              placement="right"
            >
              <Link
                style={{
                  display: "flex",
                  textDecoration: "none",
                }}
                to={listItemsMapper[listItem]?.to}
              >
                <ListItem
                  sx={{
                    color: "white",
                    opacity: "70%",
                    position: "relative",
                    "&:hover svg, &:focus svg": {
                      color: "#2F80ED",
                    },
                    "&:hover:before,&:focus:before": {
                      content: '""',
                      position: "absolute",
                      left: 0,
                      width: "3px",
                      height: "100%",
                      background: "#2F80ED",
                    },
                  }}
                  id={listItemsMapper[listItem]?.listText}
                  onClick={() => {
                    this.props.changePage(listItemsMapper[listItem]?.listText);
                  }}
                  key={index}
                >
                  <ListItemIcon
                    sx={{
                      opacity:
                        this.props.CurrentPage ===
                        listItemsMapper[listItem]?.listText
                          ? ""
                          : "50%",
                      "& svg": {
                        fontSize:
                          this.props.CurrentPage ===
                          listItemsMapper[listItem]?.listText
                            ? 34
                            : 32,
                        color: "white",
                      },
                      "&:hover svg, &:focus svg": {
                        color: "#2F80ED",
                      },
                    }}
                  >
                    {listItemsMapper[listItem]?.listIcon}
                  </ListItemIcon>
                  <ListItemText
                    primary={listItemsMapper[listItem]?.listText}
                    sx={{
                      fontSize: "20px",
                      color: "white",
                      fontFamily: "Poppins",
                      fontWeight:
                        this.props.CurrentPage ===
                        listItemsMapper[listItem]?.listText
                          ? "bold"
                          : "400",
                    }}
                  />
                </ListItem>
              </Link>
            </Tooltip>
          ))}
        </List>
      </Box>
    );
    return (
      <div style={{ display: "flex" }}>
        <CssBaseline />
        <Drawer
          open={open}
          variant="permanent"
          onClose={this.handleClick}
          sx={{
            width: drawerWidth,
            background: "#162B42",
            whiteSpace: "nowrap",
            ...(open && {
              width: drawerWidth,
              transition: (theme) =>
                theme.transitions.create("width", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
            }),
            ...(!open && {
              transition: (theme) =>
                theme.transitions.create("width", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
              overflowX: "hidden",
              width: (theme) => `calc(${theme.spacing(9)} + 1px)`,
            }),
          }}
          PaperProps={{
            sx: {
              width: drawerWidth,
              background: "#162B42",
              whiteSpace: "nowrap",
              ...(open && {
                width: drawerWidth,
                transition: (theme) =>
                  theme.transitions.create("width", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                  }),
              }),
              ...(!open && {
                transition: (theme) =>
                  theme.transitions.create("width", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                  }),
                overflowX: "hidden",
                width: (theme) => `calc(${theme.spacing(9)} + 1px)`,
              }),
            },
          }}
        >
          {sideList()}
        </Drawer>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  CurrentPage: state.CurrentPage,
  open: state.LeftSideBarVariables.open,
  userGroup: state.UserDataVariables.userGroup,
  listItems: state.UserDataVariables.listItems,
});
const mapDispatchToProps = (dispatch) => {
  return {
    changePage: (val) => dispatch({ type: "CHANGE_PAGE", payload: val }),
    toggleLeftSideBar: () => dispatch({ type: "TOGGLE_LEFT_SIDE_BAR" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar);
