import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import { connect } from "react-redux";
import logo from "../../Images/akron-logo.png";

class Home extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <>
        <Grid
          container
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={1} sm={1} />
          <Grid item xs={10} sm={10} sx={{ marginTop: 3, textAlign: "center" }}>
            <Typography
              variant="h4"
              sx={{ marginLeft: "2%", color: "#162B42", fontWeight: "bold" }}
            >
              Welcome to Akron City Integration
            </Typography>
          </Grid>
          <Grid item xs={1} sm={1} />
          <Grid />
          <Grid item xs={5} />
          <Grid item xs={2} sx={{ marginTop: 5 }}>
            <img src={logo} alt="" className="home_logo" />
          </Grid>
          <Grid item xs={5} />
        </Grid>
        <Grid
          container
          sx={{
            marginTop: 8,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={10} sx={{}}>
            <Typography
              variant="h6"
              sx={{ marginLeft: "2%", color: "#162B42", fontWeight: "bold" }}
            >
              Description:
            </Typography>
            <Typography
              variant="h6"
              sx={{ marginLeft: "2%", color: "#162B42" }}
            >
              This application helps to track the signature on bond and
              complaint forms. It also helps in maintaining schedule based email
              sending. It also provides an interface to upload docket on daily
              basis for e-signature integration.
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }
}
export const mapStateToProps = (state) => ({
  rank: state.UserDataVariables.userGroup,
  user_id: state.UserDataVariables.username,
});
export const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
