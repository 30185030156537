import React, { Component } from "react";
import { connect } from "react-redux";

import PDFViewer from "../PDFViewer";
import SingleFileUploader from "../SingleFileUploader";

import { Box, Button, Card, TextField, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SingleValueDropDown from "../SingleValueDropDown";
import IOSSwitch from "../IOSSwitch";

import * as api from "../../../Api/ApiCallbacks";

class DocSignCoordinates extends Component {
  render() {
    const {
      fileUrl,
      documentType,
      documentTypes,
      signatureParty,
      signatureParties,
      coordinates,
      signatureHintText,
      deletedIDs,
      updateMaintenanceForm,
    } = this.props;
    let selectedSignatureParties = Object.keys(coordinates);
    return (
      <Box className="page-container">
        <SingleFileUploader supportedFiles="pdf" accept="application/pdf" />
        {this.props.docSignatures.hasOwnProperty(
          documentType?.documentTypeID
        ) ? (
          <Box className="button-container">
            <Button
              onClick={() =>
                this.props.updateSignCoordinates(
                  documentType,
                  coordinates,
                  deletedIDs
                )
              }
              variant="contained"
            >
              Update
            </Button>
          </Box>
        ) : (
          <Box className="button-container">
            <Button
              disabled={api.isEmpty(coordinates)}
              onClick={() =>
                this.props.updateSignCoordinates(
                  documentType,
                  coordinates,
                  deletedIDs
                )
              }
              variant="contained"
            >
              Submit
            </Button>
          </Box>
        )}
        <Box className="doc-sign-container">
          <Box className="doc-sign-container-left">
            <Box className="flex-container-column-gap">
              <Box className="flex-container-row-gap">
                <SingleValueDropDown
                  selected={documentType}
                  options={documentTypes}
                  label="documentTypeID"
                  textFieldLabel="Document Type"
                  page="DocumentSignatureMaintenanceVariables"
                  addType="addDocSign"
                  addTypeData="documentType"
                />
                <SingleValueDropDown
                  selected={signatureParty}
                  options={signatureParties}
                  label="signaturePartyName"
                  textFieldLabel="Signature Party"
                  page="DocumentSignatureMaintenanceVariables"
                  addType="addDocSign"
                  addTypeData="signatureParty"
                />
              </Box>
              <TextField
                id="signature-hint-text"
                label="Signature Hint Text"
                type="search"
                name="signatureHintText"
                value={signatureHintText}
                fullWidth
                onChange={(event) =>
                  updateMaintenanceForm(event.target.name, event.target.value)
                }
              />
              <Button
                onClick={() =>
                  this.props.updateDocPartyDetails(
                    documentType,
                    signatureParty,
                    signatureHintText
                  )
                }
                variant="contained"
              >
                Set Details
              </Button>
            </Box>
            {selectedSignatureParties.map((selectedSignatureParty, index) => {
              return (
                <Box className="party-sign-coordinate-container" key={index}>
                  <Box className="form-container">
                    <Typography variant="h6">
                      {selectedSignatureParty}
                    </Typography>
                    <IOSSwitch
                      label="Required *"
                      page="DocumentSignatureMaintenanceVariables"
                      addType="addDocSign"
                      size="medium"
                      addTypeVar={selectedSignatureParty}
                      addTypeData="isRequired"
                      checked={coordinates[selectedSignatureParty].isRequired}
                    />
                  </Box>

                  <Box className="party-sign-coordinate-card">
                    {coordinates[selectedSignatureParty] && (
                      <Card>
                        <div className="parent-coordinate-card">
                          <div className="party-sign-card-left">
                            <div className="party-sign-card-para">
                              <Typography
                                variant="body2"
                                className="party-coordinate-a"
                              >
                                <b>
                                  x<sub>0</sub>:
                                </b>{" "}
                                {Math.floor(
                                  coordinates[selectedSignatureParty].boundary[
                                    "x0"
                                  ]
                                )}
                              </Typography>
                              <Typography
                                variant="body2"
                                className="party-coordinate-a"
                              >
                                <b>
                                  y<sub>0</sub>:
                                </b>{" "}
                                {Math.floor(
                                  coordinates[selectedSignatureParty].boundary[
                                    "y0"
                                  ]
                                )}
                              </Typography>
                            </div>
                            <div className="party-sign-card-para">
                              <Typography
                                variant="body2"
                                className="party-coordinate-a"
                              >
                                <b>
                                  x<sub>1</sub>:
                                </b>{" "}
                                {Math.ceil(
                                  coordinates[selectedSignatureParty].boundary[
                                    "x1"
                                  ]
                                )}
                              </Typography>
                              <Typography
                                variant="body2"
                                className="party-coordinate-a"
                              >
                                <b>
                                  y<sub>1</sub>:
                                </b>{" "}
                                {Math.ceil(
                                  coordinates[selectedSignatureParty].boundary[
                                    "y1"
                                  ]
                                )}
                              </Typography>
                            </div>
                            <div className="party-sign-card-para-last">
                              <p className="helper-text">
                                {
                                  coordinates[selectedSignatureParty]
                                    .signatureHintText
                                }
                              </p>
                            </div>

                            <div className="party-sign-card-para-last">
                              <Typography variant="body2">
                                <b>Page No:</b>
                                {coordinates[selectedSignatureParty].page}
                              </Typography>
                            </div>
                          </div>
                          <div className="party-sign-card-right">
                            <DeleteIcon
                              sx={{ cursor: "pointer", color: "grey" }}
                              onClick={(event) => {
                                event.stopPropagation();
                                this.props.deleteDocSignCoordinate(
                                  selectedSignatureParty
                                );
                              }}
                            />
                            <IOSSwitch
                              label="Initials"
                              page="DocumentSignatureMaintenanceVariables"
                              addType="addDocSign"
                              addTypeVar={selectedSignatureParty}
                              addTypeData="isInitial"
                              size="small"
                              checked={
                                coordinates[selectedSignatureParty].isInitial
                              }
                            />
                          </div>
                        </div>
                      </Card>
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box className="doc-sign-container-right">
            {fileUrl !== null ? (
              <PDFViewer pdfUrl={fileUrl} />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                No PDF uploaded
              </div>
            )}
          </Box>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  id: state.DocumentSignatureMaintenanceVariables.addDocSign.id,
  fileUrl: state.DocumentSignatureMaintenanceVariables.addDocSign.fileUrl,
  signatureHintText:
    state.DocumentSignatureMaintenanceVariables.addDocSign.signatureHintText,
  documentType:
    state.DocumentSignatureMaintenanceVariables.addDocSign.documentType,
  documentTypes: state.DocumentTypeMaintenanceVariables.documentTypes,
  signatureParty:
    state.DocumentSignatureMaintenanceVariables.addDocSign.signatureParty,
  signatureParties: state.SignaturePartyMaintenanceVariables.signatureParties,
  coordinates:
    state.DocumentSignatureMaintenanceVariables.addDocSign.coordinates,
  docSignatures: state.DocumentSignatureMaintenanceVariables.docSignatures,
  deletedIDs: state.DocumentSignatureMaintenanceVariables.addDocSign.deletedIDs,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateMaintenanceForm: (name, value) =>
      dispatch({
        type: "UPDATE_MAINTENANCE_FORM",
        payload: {
          page: "DocumentSignatureMaintenanceVariables",
          addType: "addDocSign",
          name,
          value,
        },
      }),
    deleteDocSignCoordinate: (selectedSignatureParty) =>
      dispatch({
        type: "DELETE_DOC_SIGN_COORDINATES",
        payload: selectedSignatureParty,
      }),
    updateSignCoordinates: (documentType, coordinates, deletedIDs) =>
      api.updateSignCoordinates(
        {
          documentType,
          coordinates,
          deletedIDs,
        },
        dispatch
      ),
    updateDocPartyDetails: (documentType, signatureParty, signatureHintText) =>
      dispatch({
        type: "UPDATE_DOC_PARTY_DETAILS",
        payload: { documentType, signatureParty, signatureHintText },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocSignCoordinates);
