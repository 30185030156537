import React, { Component } from "react";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { connect } from "react-redux";
import * as api from "../../Api/ApiCallbacks";
import logo from "../../Images/quicket-logo.svg";
import { Button, Typography } from "@mui/material";
import { Link, Navigate } from "react-router-dom";

class Login extends Component {
  render() {
    const { username, password, isAuthenticated, isForceChangePassword } =
      this.props;
    if (isAuthenticated) {
      return <Navigate to="/home" replace={true} />;
    }
    if (isForceChangePassword) {
      return <Navigate to="/set_new_password" replace={true} />;
    }
    return (
      <div className="auth-page-container">
        <Box className="login-container">
          <Box className="login-form-container">
            <img src={logo} alt="" style={{ marginBottom: 15 }} />
            <Typography
              variant="h6"
              sx={{
                color: "#4D5B7C",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              Login to Akron City, OHIO
            </Typography>
            <TextField
              required
              fullWidth
              id="username"
              label="Email"
              name="username"
              autoComplete="username"
              autoFocus
              onChange={(event) => {
                this.props.updateAuthForm(
                  event.target.name,
                  event.target.value
                );
              }}
            />
            <TextField
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(event) => {
                this.props.updateAuthForm(
                  event.target.name,
                  event.target.value
                );
              }}
            />
            <Link
              className="link-url"
              style={{ justifyContent: "flex-end" }}
              to="/forgot_password"
            >
              Forgot Password?
            </Link>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={(e) => {
                e.preventDefault();
                this.props.cognitoSignIn(username, password);
              }}
            >
              Sign In
            </Button>
            {/* <Link className="link-url" to="/signup">
              Create a new User
            </Link> */}
          </Box>
        </Box>
      </div>
    );
  }
}

export const mapStateToProps = (state) => ({
  username: state.LoginPageVariables.username,
  password: state.LoginPageVariables.password,
  isAuthenticated: state.UserDataVariables.isAuthenticated,
  isForceChangePassword: state.LoginPageVariables.isForceChangePassword,
});
export const mapDispatchToProps = (dispatch) => {
  return {
    updateAuthForm: (name, value) =>
      dispatch({
        type: "UPDATE_AUTH_FORM",
        payload: { name: name, value: value, page: "LoginPageVariables" },
      }),
    cognitoSignIn: (username, password) =>
      api.cognitoSignIn(username, password, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
