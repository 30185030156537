import React, { Component } from "react";
import { connect } from "react-redux";

import Modal from "@mui/material/Modal";
import { Box } from "@mui/system";
import { IconButton, Typography } from "@mui/material";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import SignSequence from "./SignSequence";

import * as api from "../../Api/ApiCallbacks";

class SignatureHistoryModal extends Component {
  render() {
    const { modalDetails, downloadSignaturePDF, showModal, closeModal } =
      this.props;
    return (
      <>
        <Modal
          open={showModal}
          onClose={() => closeModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal-container">
            <Typography variant="h5" component="div">
              Case Details
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 2,
              }}
            >
              <Typography variant="body1" color="text.secondary">
                <b>Case Number:</b> {modalDetails.caseNbr}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                <b>Defendant Name:</b>{" "}
                {api.getDefendantName(
                  modalDetails.firstName,
                  modalDetails.middleName,
                  modalDetails.lastName
                )}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                <b>Case ID:</b> {modalDetails.caseID}
              </Typography>
            </Box>
            <Typography
              sx={{ marginTop: 2, fontSize: 20 }}
              variant="body1"
              color="text.primary"
            >
              Document Details
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body1" color="text.secondary">
                <b>ID:</b> {modalDetails?.document?.documentID}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                <b>Name: </b>
                {modalDetails?.document?.documentName}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                <b>Type:</b> {modalDetails?.document?.documentType}
              </Typography>
              {/* <Typography variant="body1" color="text.secondary">
                <b>Document Type:</b> {modalDetails?.document?.documentTypeName}
              </Typography> */}
              {/* <Typography variant="body1" color="text.secondary">
                <b>Type Description:</b>{" "}
                {modalDetails?.document?.documentTypeDesc}
              </Typography> */}
              <Typography variant="body1" color="text.secondary">
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    downloadSignaturePDF(
                      modalDetails?.document?.id,
                      modalDetails?.document?.documentName
                    );
                  }}
                >
                  <DownloadRoundedIcon />
                </IconButton>
              </Typography>
            </Box>
            <Box sx={{ marginTop: 2 }}>
              <SignSequence
                caseSignatures={modalDetails?.document?.caseSignatures}
              />
            </Box>
          </Box>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  modalDetails: state.ModalDetailsPageVariables.modalDetails,
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () =>
      dispatch({
        type: "CLOSE_MODAL",
        payload: { page: "SignatureHistoryPageVariables" },
      }),
    downloadSignaturePDF: (id, documentName) =>
      api.downloadSignaturePDF(id, documentName),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignatureHistoryModal);
