import { Button, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { Component } from "react";
import { connect } from "react-redux";

import * as api from "../../../Api/ApiCallbacks";
import SingleValueDropDown from "../SingleValueDropDown";

export class JailEmailModal extends Component {
  render() {
    const {
      id,
      emailID,
      subjectPurpose,
      emailSubject,
      emailBody,
      subjects,
      showModal,
      closeJailEmailModal,
      updateMaintenanceForm,
      formEnabled,
    } = this.props;
    return (
      <div>
        <Modal open={showModal} onClose={() => closeJailEmailModal()}>
          <Box className="modal-container">
            <Box className="doc-modal-container-form">
              {id === "" ? (
                <p className="doc-modal-container-para">
                  Enter Details to add new Jail Email Purpose:
                </p>
              ) : (
                <p className="doc-modal-container-para">
                  Enter Details to update Jail Email Purpose:
                </p>
              )}
              <Box className="doc-modal-container-form-1">
                <TextField
                  id="email-id"
                  label="Email ID"
                  type="email"
                  name="emailID"
                  value={emailID}
                  fullWidth
                  onChange={(event) =>
                    updateMaintenanceForm(event.target.name, event.target.value)
                  }
                />
                <SingleValueDropDown
                  selected={subjectPurpose}
                  options={subjects}
                  label="subjectPurpose"
                  textFieldLabel="Subject Purpose"
                  page="JailEmailMaintenanceVariables"
                  addType="addEmailPurpose"
                  addTypeData="subjectPurpose"
                />
              </Box>
              <TextField
                id="email-body"
                label="Email Subject"
                type="search"
                name="emailSubject"
                value={emailSubject}
                onChange={(event) =>
                  updateMaintenanceForm(event.target.name, event.target.value)
                }
              />
              <TextField
                id="email-body"
                label="Email Body"
                type="search"
                name="emailBody"
                value={emailBody}
                onChange={(event) =>
                  updateMaintenanceForm(event.target.name, event.target.value)
                }
              />
              <Box className="button-container">
                {id === "" ? (
                  <Button
                    disabled={!formEnabled}
                    onClick={() =>
                      this.props.submitJailEmailForm(
                        emailID,
                        subjectPurpose,
                        emailSubject,
                        emailBody
                      )
                    }
                    variant="contained"
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    disabled={!formEnabled}
                    onClick={() =>
                      this.props.updateJailEmailForm(
                        id,
                        emailID,
                        subjectPurpose,
                        emailSubject,
                        emailBody
                      )
                    }
                    variant="contained"
                  >
                    Update
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  id: state.JailEmailMaintenanceVariables.addEmailPurpose.id,
  emailID: state.JailEmailMaintenanceVariables.addEmailPurpose.emailID,
  subjectPurpose:
    state.JailEmailMaintenanceVariables.addEmailPurpose.subjectPurpose,
  emailSubject:
    state.JailEmailMaintenanceVariables.addEmailPurpose.emailSubject,
  emailBody: state.JailEmailMaintenanceVariables.addEmailPurpose.emailBody,
  subjects: state.JailEmailDocumentMaintenanceVariables.jailEmailDocs,
  formEnabled: state.JailEmailMaintenanceVariables.formEnabled,
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeJailEmailModal: () =>
      dispatch({
        type: "CLOSE_JAIL_EMAIL_MODAL",
      }),
    updateMaintenanceForm: (name, value) =>
      dispatch({
        type: "UPDATE_MAINTENANCE_FORM",
        payload: {
          page: "JailEmailMaintenanceVariables",
          addType: "addEmailPurpose",
          name,
          value,
        },
      }),
    submitJailEmailForm: (emailID, subjectPurpose, emailSubject, emailBody) =>
      api.submitJailEmailForm(
        {
          emailID,
          subjectPurpose,
          emailSubject,
          emailBody,
        },
        dispatch
      ),
    updateJailEmailForm: (
      id,
      emailID,
      subjectPurpose,
      emailSubject,
      emailBody
    ) =>
      api.updateJailEmailForm(
        {
          id,
          emailID,
          subjectPurpose,
          emailSubject,
          emailBody,
        },
        dispatch
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JailEmailModal);
