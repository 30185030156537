import React, { Component } from "react";
import { connect } from "react-redux";

import { styled } from "@mui/material/styles";

import CancelIcon from "@mui/icons-material/Cancel";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Box } from "@mui/system";

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

class SignSequence extends Component {
  ColorlibStepIcon = (index, label, completed) => {
    let icon = null;
    switch (completed) {
      case true:
        icon = <TaskAltIcon />;
        break;
      case false:
        icon = <CancelIcon />;
        break;
      default:
        icon = <TaskAltIcon />;
        break;
    }

    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "0.25rem",
        }}
        key={index}
      >
        <ColorlibStepIconRoot ownerState={{ completed }} className={label}>
          {icon}
        </ColorlibStepIconRoot>
        <div style={{ textAlign: "center" }}>{label}</div>
      </Box>
    );
  };
  render() {
    const { caseSignatures } = this.props;
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        {caseSignatures.map((signature, index) => {
          return this.ColorlibStepIcon(
            index,
            signature.signaturePartyName,
            signature.signedStatus
          );
        })}
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignSequence);
