import React from "react";
import { useSelector } from "react-redux";

import { Navigate, Outlet } from "react-router-dom";

export const ProtectedUserLayout = ({ children }) => {
  const isAuthenticated = useSelector(
    (state) => state.UserDataVariables.isAuthenticated
  );
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  return (
    <div>
      <Outlet />
    </div>
  );
};
