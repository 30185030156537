import React, { Component } from "react";
import { connect } from "react-redux";

import Modal from "@mui/material/Modal";
import { Box } from "@mui/system";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import * as api from "../../Api/ApiCallbacks";

class JailEmailHistoryModal extends Component {
  render() {
    const { modalDetails, showModal, closeModal, downloadSignaturePDF } =
      this.props;
    let i = 0;
    return (
      <>
        <Modal
          open={showModal}
          onClose={() => closeModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modal-container">
            <Typography variant="h5" component="div">
              Jail Email Details
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 2,
              }}
            >
              <Typography variant="body1" color="text.secondary">
                <b>ID:</b> {modalDetails.id}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                <b>Case No.:</b> {modalDetails.caseNbr}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                <b>Sending Datetime:</b>{" "}
                {api.convertDatetime(modalDetails.sendingDatetime)}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {modalDetails.runStatus === "FAILED" ? (
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();
                      this.props.resendJailEmail(modalDetails.id);
                    }}
                    variant="contained"
                  >
                    Re Send
                  </Button>
                ) : modalDetails.runStatus === "SUCCESS" ? (
                  "SENT"
                ) : (
                  "SENDING"
                )}
              </Typography>
            </Box>
            <Typography
              sx={{ marginTop: 2, fontSize: 20 }}
              variant="body1"
              color="text.primary"
            >
              <b>Recipient Mail IDs:</b>
            </Typography>
            <div style={{ maxHeight: "23vh", overflow: "auto" }}>
              <ul>
                {modalDetails?.recipientEmailID?.map((emailID, index) => {
                  return <li key={index}>{emailID}</li>;
                })}
              </ul>
            </div>
            <Typography
              sx={{ marginTop: 2, fontSize: 20 }}
              variant="body1"
              color="text.primary"
            >
              <b>Document Details</b>
            </Typography>
            <Paper>
              <TableContainer sx={{ maxHeight: "25vh" }}>
                <Table
                  stickyHeader
                  aria-label="signature-history-table"
                  className="table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>S. No.</TableCell>
                      <TableCell>Document ID</TableCell>
                      <TableCell>Document Name</TableCell>
                      <TableCell>Download</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {modalDetails?.documents?.map((document) => {
                      i = i + 1;
                      return (
                        <TableRow role="checkbox" tabIndex={-1} key={i}>
                          <TableCell>{i}</TableCell>
                          <TableCell>{document.documentID}</TableCell>
                          <TableCell>{document.documentName}</TableCell>
                          <TableCell>
                            <Button
                              onClick={(event) => {
                                event.stopPropagation();
                                downloadSignaturePDF(
                                  document.id,
                                  document.documentName
                                );
                              }}
                              variant="contained"
                            >
                              Download
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  modalDetails: state.ModalDetailsPageVariables.modalDetails,
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () =>
      dispatch({
        type: "CLOSE_MODAL",
        payload: { page: "JailEmailHistoryVariables" },
      }),
    downloadSignaturePDF: (id, documentName) =>
      api.downloadSignaturePDF(id, documentName),
    resendJailEmail: (id) => api.resendJailEmail(id, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JailEmailHistoryModal);
