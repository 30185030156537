import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
  Typography,
} from "@mui/material";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import dayjs from "dayjs";
import { DatePicker } from "antd";
import customParseFormat from "dayjs/plugin/customParseFormat";

import * as api from "../../Api/ApiCallbacks";

import DocketHistoryModal from "./DocketHistoryModal";
import CircularProgressLoader from "./CircularProgressLoader";

export class DocketHistory extends Component {
  render() {
    dayjs.extend(customParseFormat);
    const dateFormat = "MM/DD/YYYY";
    const { RangePicker } = DatePicker;
    let i = 0;
    const {
      page,
      rowsPerPage,
      downloadDocket,
      dockets,
      handleChangeRowsPerPage,
      handleChangePage,
      showModal,
      openModal,
      caseNbr,
      firstName,
      middleName,
      lastName,
      startDate,
      endDate,
      formEnabled,
      updateDocketForm,
      updateDocketDateForm,
    } = this.props;
    return (
      <Box className="page-container">
        <DocketHistoryModal showModal={showModal} />
        <Box className="form-container">
          <TextField
            id="case-number"
            fullWidth
            label="Case Number"
            type="search"
            name="caseNbr"
            value={caseNbr}
            onChange={(event) =>
              updateDocketForm(event.target.name, event.target.value)
            }
          />
          <RangePicker
            className="range-picker"
            onChange={(dates, dateString) =>
              updateDocketDateForm(dates, dateString)
            }
            format={dateFormat}
            value={[
              startDate !== "" ? dayjs(startDate, dateFormat) : null,
              endDate !== "" ? dayjs(endDate, dateFormat) : null,
            ]}
          />
          <TextField
            id="first-name"
            fullWidth
            label="First Name"
            type="search"
            name="firstName"
            value={firstName}
            onChange={(event) =>
              this.props.updateDocketForm(event.target.name, event.target.value)
            }
          />
          <TextField
            id="middle-name"
            fullWidth
            label="Middle Name"
            type="search"
            name="middleName"
            value={middleName}
            onChange={(event) =>
              this.props.updateDocketForm(event.target.name, event.target.value)
            }
          />
          <TextField
            id="last-name"
            fullWidth
            label="Last Name"
            type="search"
            name="lastName"
            value={lastName}
            onChange={(event) =>
              this.props.updateDocketForm(event.target.name, event.target.value)
            }
          />
        </Box>
        <Box className="button-container">
          <Button
            disabled={!formEnabled}
            onClick={() =>
              this.props.submitDocketForm(
                caseNbr,
                startDate,
                endDate,
                firstName,
                middleName,
                lastName
              )
            }
            variant="contained"
          >
            Search
          </Button>
        </Box>
        {this.props.isLoading ? (
          <Box className="flex-container">
            <CircularProgressLoader />
          </Box>
        ) : (
          <>
            {dockets.length === 0 ? (
              <Box>
                <Typography>No dockets found</Typography>
              </Box>
            ) : (
              <Paper>
                <TableContainer className="table-container">
                  <Table
                    stickyHeader
                    aria-label="docket-history-table"
                    className="table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>S. No.</TableCell>
                        <TableCell>Docket Name</TableCell>
                        <TableCell>Upload Date</TableCell>
                        <TableCell>Document</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dockets
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          let sNo = rowsPerPage * page + i + 1;
                          i = i + 1;
                          return (
                            <TableRow
                              hover
                              onClick={() =>
                                openModal(row.fileName, row.docketData)
                              }
                              role="checkbox"
                              tabIndex={-1}
                              key={sNo}
                            >
                              <TableCell>{sNo}</TableCell>
                              <TableCell>{row.fileName}</TableCell>
                              <TableCell>
                                {api.convertDate(row.uploadDateTime)}
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    downloadDocket(row.id, row.fileName);
                                  }}
                                >
                                  <DownloadRoundedIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={dockets.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(newPageNumber) =>
                    handleChangePage(newPageNumber)
                  }
                  onRowsPerPageChange={(event) =>
                    handleChangeRowsPerPage(event.target.value)
                  }
                />
              </Paper>
            )}
          </>
        )}
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  dockets: state.DocketHistoryPageVariables.dockets,
  page: state.DocketHistoryPageVariables.page,
  rowsPerPage: state.DocketHistoryPageVariables.rowsPerPage,
  showModal: state.ModalDetailsPageVariables.showModal,
  caseNbr: state.DocketHistoryPageVariables.caseNbr,
  startDate: state.DocketHistoryPageVariables.startDate,
  endDate: state.DocketHistoryPageVariables.endDate,
  firstName: state.DocketHistoryPageVariables.firstName,
  middleName: state.DocketHistoryPageVariables.middleName,
  lastName: state.DocketHistoryPageVariables.lastName,
  formEnabled: state.DocketHistoryPageVariables.formEnabled,
  isLoading: state.CircularProgressVariables.isLoading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleChangePage: (newPageNumber) =>
      dispatch({
        type: "HANDLE_CHANGE_PAGE",
        payload: {
          page: "DocketHistoryPageVariables",
          newPageNumber: newPageNumber,
        },
      }),
    handleChangeRowsPerPage: (val) =>
      dispatch({
        type: "HANDLE_CHANGE_ROWS_PER_PAGE",
        payload: { page: "DocketHistoryPageVariables", data: val },
      }),
    openModal: (filename, data) =>
      dispatch({
        type: "OPEN_MODAL",
        payload: {
          page: "DocketHistoryPageVariables",
          key: filename,
          data: data,
        },
      }),
    downloadSignaturePDF: (val) =>
      dispatch({
        type: "DOWNLOAD_SIGNATURE_PDF",
        payload: val,
      }),
    updateDocketForm: (name, value) =>
      dispatch({
        type: "UPDATE_DOCKET_FORM",
        payload: { name, value },
      }),
    updateDocketDateForm: (dates, dateString) =>
      dispatch({
        type: "UPDATE_DOCKET_DATE_FORM",
        payload: { dates, dateString },
      }),
    submitDocketForm: (
      caseNbr,
      startDate,
      endDate,
      firstName,
      middleName,
      lastName
    ) =>
      api.submitDocketForm(
        { caseNbr, startDate, endDate, firstName, middleName, lastName },
        dispatch
      ),
    downloadDocket: (id, documentName) => api.downloadDocket(id, documentName),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocketHistory);
