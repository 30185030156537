import React, { Component } from "react";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { connect } from "react-redux";
import * as api from "../../Api/ApiCallbacks";
import logo from "../../Images/quicket-logo.svg";
import { Button, Typography } from "@mui/material";
import { Link, Navigate } from "react-router-dom";

const unloadCallback = (event) => {
  const e = event || window.event;
  //console.log(e)
  e.preventDefault();
  if (e) {
    e.returnValue = "";
  }
  return "";
};

const handlePopState = (props) => {
  window.alert(
    "You are navigating away from this page, all your changes will be lost!"
  );
  props.clearForgotPasswordVariables();
};

class ForgotPassword extends Component {
  componentDidMount() {
    window.addEventListener("beforeunload", unloadCallback);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", unloadCallback);
  }

  render() {
    const { username, newPassword, code, getCodeInitiated, isAuthenticated } =
      this.props;
    if (isAuthenticated) {
      return <Navigate to="/home" replace={true} />;
    }
    return (
      <div className="auth-page-container">
        <Box className="login-container">
          <Box className="login-form-container">
            <img src={logo} alt="" style={{ marginBottom: 15 }} />
            <Typography
              variant="h6"
              sx={{
                color: "#4D5B7C",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              Password reset for Akron City, OHIO
            </Typography>
            <TextField
              disabled={getCodeInitiated}
              required
              fullWidth
              id="username"
              label="Email"
              name="username"
              value={username}
              autoComplete="username"
              autoFocus
              onChange={(event) => {
                this.props.updateAuthForm(
                  event.target.name,
                  event.target.value
                );
              }}
            />
            <Button
              disabled={getCodeInitiated}
              type="submit"
              fullWidth
              variant="contained"
              onClick={(e) => {
                e.preventDefault();
                this.props.getCode(username);
              }}
            >
              Get Code
            </Button>
            <br />
            <TextField
              required
              fullWidth
              name="newPassword"
              label="New Password"
              type="password"
              id="newPassword"
              value={newPassword}
              autoComplete="newPassword"
              onChange={(event) => {
                this.props.updateAuthForm(
                  event.target.name,
                  event.target.value
                );
              }}
            />
            <TextField
              required
              fullWidth
              name="code"
              label="Code"
              type="text"
              id="code"
              value={code}
              autoComplete="code"
              onChange={(event) => {
                this.props.updateAuthForm(
                  event.target.name,
                  event.target.value
                );
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={(e) => {
                e.preventDefault();
                this.props.setNewPassword(username, newPassword, code);
              }}
            >
              Set new password
            </Button>
            <div className="flex-container-row-gap">
              <Link
                onClick={() => handlePopState(this.props)}
                className="link-url"
                to="/login"
              >
                Already a user?
              </Link>
            </div>
          </Box>
        </Box>
      </div>
    );
  }
}

export const mapStateToProps = (state) => ({
  username: state.ForgotPasswordPageVariables.username,
  newPassword: state.ForgotPasswordPageVariables.newPassword,
  code: state.ForgotPasswordPageVariables.code,
  getCodeInitiated: state.ForgotPasswordPageVariables.getCodeInitiated,
  isAuthenticated: state.UserDataVariables.isAuthenticated,
});
export const mapDispatchToProps = (dispatch) => {
  return {
    updateAuthForm: (name, value) =>
      dispatch({
        type: "UPDATE_AUTH_FORM",
        payload: {
          name: name,
          value: value,
          page: "ForgotPasswordPageVariables",
        },
      }),
    getCode: (username) => api.getCode(username, dispatch),
    clearForgotPasswordVariables: () =>
      dispatch({
        type: "CLEAR_FORGOT_PASSWORD_VARIABLES",
      }),
    setNewPassword: (username, newPassword, code) =>
      api.setNewPassword(username, newPassword, code, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
