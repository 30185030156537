import React, { Component } from "react";
import { connect } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class MultiValueDropDown extends Component {
  render() {
    const {
      selected,
      options,
      label,
      textFieldLabel,
      page,
      addType,
      addTypeData,
      handleMultiDropdownValueChange,
    } = this.props;

    return (
      <Autocomplete
        multiple
        fullWidth
        options={options}
        value={selected}
        getOptionLabel={(option) => option[label]}
        disableCloseOnSelect
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option[label]}
          </li>
        )}
        isOptionEqualToValue={(option, value) => {
          return option[label] === value[label];
        }}
        onChange={(event, newValue) => {
          handleMultiDropdownValueChange(newValue, page, addType, addTypeData);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={textFieldLabel}
            placeholder={textFieldLabel}
          />
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    handleMultiDropdownValueChange: (val, page, addType, addTypeData) =>
      dispatch({
        type: "HANDLE_MULTI_DROPDOWN_VALUE_CHANGE",
        payload: { page, addType, addTypeData, val },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiValueDropDown);
