import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Button,
  Typography,
} from "@mui/material";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import dayjs from "dayjs";
import { DatePicker } from "antd";
import customParseFormat from "dayjs/plugin/customParseFormat";

import SignatureHistoryModal from "./SignatureHistoryModal";
import CircularProgressLoader from "./CircularProgressLoader";

import * as api from "../../Api/ApiCallbacks";

class SignatureHistory extends Component {
  render() {
    dayjs.extend(customParseFormat);
    const dateFormat = "MM/DD/YYYY";
    const { RangePicker } = DatePicker;
    let i = 0;
    const {
      page,
      rowsPerPage,
      caseNbr,
      startDate,
      endDate,
      firstName,
      middleName,
      lastName,
      downloadSignaturePDF,
      signaturesData,
      handleChangeRowsPerPage,
      handleChangePage,
      formEnabled,
      showModal,
      openModal,
    } = this.props;
    return (
      <Box className="page-container">
        <SignatureHistoryModal showModal={showModal} />
        <Box className="form-container">
          <TextField
            id="case-number"
            fullWidth
            label="Case Number"
            type="search"
            name="caseNbr"
            value={caseNbr}
            onChange={(event) =>
              this.props.updateSignHistoryForm(
                event.target.name,
                event.target.value
              )
            }
          />
          <RangePicker
            className="range-picker"
            onChange={(dates, dateString) =>
              this.props.updateSignDateForm(dates, dateString)
            }
            format={dateFormat}
            value={[
              startDate !== "" ? dayjs(startDate, dateFormat) : null,
              endDate !== "" ? dayjs(endDate, dateFormat) : null,
            ]}
          />
          <TextField
            id="first-name"
            fullWidth
            label="First Name"
            type="search"
            name="firstName"
            value={firstName}
            onChange={(event) =>
              this.props.updateSignHistoryForm(
                event.target.name,
                event.target.value
              )
            }
          />
          <TextField
            id="middle-name"
            fullWidth
            label="Middle Name"
            type="search"
            name="middleName"
            value={middleName}
            onChange={(event) =>
              this.props.updateSignHistoryForm(
                event.target.name,
                event.target.value
              )
            }
          />
          <TextField
            id="last-name"
            fullWidth
            label="Last Name"
            type="search"
            name="lastName"
            value={lastName}
            onChange={(event) =>
              this.props.updateSignHistoryForm(
                event.target.name,
                event.target.value
              )
            }
          />
        </Box>
        <Box className="button-container">
          <Button
            disabled={!formEnabled}
            onClick={() =>
              this.props.submitSignHistoryForm(
                caseNbr,
                startDate,
                endDate,
                firstName,
                middleName,
                lastName
              )
            }
            variant="contained"
          >
            Search
          </Button>
        </Box>
        {this.props.isLoading ? (
          <Box className="flex-container">
            <CircularProgressLoader />
          </Box>
        ) : (
          <>
            {signaturesData.length === 0 ? (
              <Box>
                <Typography>No signatures documents available</Typography>
              </Box>
            ) : (
              <Paper>
                <TableContainer className="table-container">
                  <Table
                    stickyHeader
                    aria-label="signature-history-table"
                    className="table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>S. No.</TableCell>
                        <TableCell>Case Number</TableCell>
                        <TableCell>Defendant Name</TableCell>
                        <TableCell>Document ID</TableCell>
                        <TableCell>Document Type</TableCell>
                        <TableCell>Document Name</TableCell>
                        <TableCell>Document</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {signaturesData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((signatureData) => {
                          let sNo = rowsPerPage * page + i + 1;
                          i = i + 1;
                          return (
                            <TableRow
                              hover
                              onClick={() =>
                                openModal(
                                  signatureData.caseNbr,
                                  signatureData.firstName,
                                  signatureData.middleName,
                                  signatureData.lastName,
                                  signatureData.startDate,
                                  signatureData.endDate,
                                  signatureData.caseID,
                                  signatureData.versionID,
                                  signatureData.docketDate,
                                  signatureData.document
                                )
                              }
                              role="checkbox"
                              tabIndex={-1}
                              key={sNo}
                            >
                              <TableCell>{sNo}</TableCell>
                              <TableCell>{signatureData.caseNbr}</TableCell>
                              <TableCell>
                                {api.getDefendantName(
                                  signatureData.firstName,
                                  signatureData.lastName,
                                  signatureData.middleName
                                )}
                              </TableCell>
                              <TableCell>
                                {signatureData.document?.documentID}
                              </TableCell>
                              <TableCell>
                                {signatureData.document?.documentTypeName}
                              </TableCell>
                              <TableCell>
                                {signatureData.document?.documentName}
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    downloadSignaturePDF(
                                      signatureData.document?.id,
                                      signatureData.document?.documentName
                                    );
                                  }}
                                >
                                  <DownloadRoundedIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={signaturesData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(event, newPage) =>
                    handleChangePage(event, newPage)
                  }
                  onRowsPerPageChange={(event) =>
                    handleChangeRowsPerPage(event.target.value)
                  }
                />
              </Paper>
            )}
          </>
        )}
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  page: state.SignatureHistoryPageVariables.page,
  caseNbr: state.SignatureHistoryPageVariables.caseNbr,
  startDate: state.SignatureHistoryPageVariables.startDate,
  endDate: state.SignatureHistoryPageVariables.endDate,
  firstName: state.SignatureHistoryPageVariables.firstName,
  middleName: state.SignatureHistoryPageVariables.middleName,
  lastName: state.SignatureHistoryPageVariables.lastName,
  signaturesData: state.SignatureHistoryPageVariables.signaturesData,
  rowsPerPage: state.SignatureHistoryPageVariables.rowsPerPage,
  formEnabled: state.SignatureHistoryPageVariables.formEnabled,
  showModal: state.ModalDetailsPageVariables.showModal,
  isLoading: state.CircularProgressVariables.isLoading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleChangePage: (event, newPageNumber) =>
      dispatch({
        type: "HANDLE_CHANGE_PAGE",
        payload: {
          page: "SignatureHistoryPageVariables",
          event,
          newPageNumber,
        },
      }),
    handleChangeRowsPerPage: (val) =>
      dispatch({
        type: "HANDLE_CHANGE_ROWS_PER_PAGE",
        payload: { page: "SignatureHistoryPageVariables", data: val },
      }),
    openModal: (
      caseNbr,
      firstName,
      middleName,
      lastName,
      startDate,
      endDate,
      caseID,
      versionID,
      docketDate,
      document
    ) =>
      dispatch({
        type: "OPEN_MODAL",
        payload: {
          page: "SignatureHistoryPageVariables",
          data: {
            caseNbr,
            firstName,
            middleName,
            lastName,
            startDate,
            endDate,
            caseID,
            versionID,
            docketDate,
            document,
          },
        },
      }),
    downloadSignaturePDF: (id, documentName) =>
      api.downloadSignaturePDF(id, documentName),
    updateSignHistoryForm: (name, value) =>
      dispatch({
        type: "UPDATE_SIGN_HISTORY_FORM",
        payload: { name, value },
      }),
    updateSignDateForm: (dates, dateString) =>
      dispatch({
        type: "UPDATE_SIGN_DATE_FORM",
        payload: { dates, dateString },
      }),
    submitSignHistoryForm: (
      caseNbr,
      startDate,
      endDate,
      firstName,
      middleName,
      lastName
    ) =>
      api.submitSignHistoryForm(
        { caseNbr, startDate, endDate, firstName, middleName, lastName },
        dispatch
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignatureHistory);
