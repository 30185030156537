import React, { Component } from "react";
import { connect } from "react-redux";

class Help extends Component {
  render() {
    return <div>Help</div>;
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Help);
