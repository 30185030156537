import React, { Component } from "react";
import { connect } from "react-redux";

import { Box, Button, Card, CardActionArea, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import DocketModal from "./DocketModal";
import * as api from "../../Api/ApiCallbacks";
import MultipleFileUploader from "./MultipleFileUploader";

class DocketUploader extends Component {
  render() {
    const { acceptedFilesKey, openModal, showModal, parsedData, docketUpload } =
      this.props;
    return (
      <div>
        <Box className="page-container">
          <DocketModal showModal={showModal} />
          <MultipleFileUploader supportedFiles="csv" accept="text/csv" />
          <Box className="button-container">
            <Button
              onClick={() => docketUpload(parsedData)}
              variant="contained"
            >
              Submit
            </Button>
          </Box>
          <Box>
            <h4>Accepted files</h4>
            <div
              style={{
                display: "flex",
                gap: "1rem",
                flexDirection: "column",
              }}
            >
              {acceptedFilesKey.map((file, index) => (
                <Card key={index} style={{ width: "100%" }}>
                  <CardActionArea
                    sx={{
                      padding: "1rem",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    onClick={() => openModal(parsedData[file], file)}
                  >
                    <Typography>{file}</Typography>
                    <DeleteIcon
                      sx={{ color: "grey" }}
                      onClick={(event) => {
                        event.stopPropagation();
                        this.props.deleteFile(file);
                      }}
                    />
                  </CardActionArea>
                </Card>
              ))}
            </div>
          </Box>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  showModal: state.ModalDetailsPageVariables.showModal,
  acceptedFilesKey: state.DocketUploaderPageVariables.acceptedFilesKey,
  parsedData: state.DocketUploaderPageVariables.parsedData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (val, filename) =>
      dispatch({
        type: "OPEN_MODAL",
        payload: {
          page: "DocketUploaderPageVariables",
          data: val,
          key: filename,
        },
      }),
    docketUpload: (val) => api.docketUpload(val, dispatch),
    deleteFile: (file) => api.deleteFile(file, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocketUploader);
