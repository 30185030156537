import { Button, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { Component } from "react";
import { connect } from "react-redux";

import * as api from "../../../Api/ApiCallbacks";

export class SignPartyModal extends Component {
  render() {
    const {
      id,
      signaturePartyName,
      showModal,
      closeSignPartyModal,
      updateMaintenanceForm,
      formEnabled,
    } = this.props;
    return (
      <div>
        <Modal open={showModal} onClose={() => closeSignPartyModal()}>
          <Box className="modal-container">
            <Box className="doc-modal-container-form">
              {id === "" ? (
                <p className="doc-modal-container-para">
                  Enter Details to add Signature Party Name:
                </p>
              ) : (
                <p className="doc-modal-container-para">
                  Enter Details to update Signature Party Name:
                </p>
              )}
              <Box className="doc-modal-container-form-1">
                <TextField
                  id="document-type-id"
                  fullWidth
                  label="Name"
                  type="search"
                  name="signaturePartyName"
                  value={signaturePartyName}
                  onChange={(event) =>
                    updateMaintenanceForm(event.target.name, event.target.value)
                  }
                />
              </Box>
              <Box className="button-container">
                {id === "" ? (
                  <Button
                    disabled={!formEnabled}
                    onClick={() =>
                      this.props.submitSignPartyForm(signaturePartyName)
                    }
                    variant="contained"
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    disabled={!formEnabled}
                    onClick={() =>
                      this.props.updateSignPartyForm(id, signaturePartyName)
                    }
                    variant="contained"
                  >
                    Update
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  id: state.SignaturePartyMaintenanceVariables.addSignatureParty.id,
  signaturePartyName:
    state.SignaturePartyMaintenanceVariables.addSignatureParty
      .signaturePartyName,
  formEnabled: state.SignaturePartyMaintenanceVariables.formEnabled,
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeSignPartyModal: () =>
      dispatch({
        type: "CLOSE_SIGN_PARTY_MODAL",
      }),
    updateMaintenanceForm: (name, value) =>
      dispatch({
        type: "UPDATE_MAINTENANCE_FORM",
        payload: {
          page: "SignaturePartyMaintenanceVariables",
          addType: "addSignatureParty",
          name,
          value,
        },
      }),
    submitSignPartyForm: (signaturePartyName) =>
      api.submitSignPartyForm(
        {
          signaturePartyName,
        },
        dispatch
      ),
    updateSignPartyForm: (id, signaturePartyName) =>
      api.updateSignPartyForm(
        {
          id,
          signaturePartyName,
        },
        dispatch
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignPartyModal);
