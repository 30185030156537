import React, { Component } from "react";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";

import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, Card } from "@mui/material";

import * as api from "../../Api/ApiCallbacks";

class MultipleFileUploader extends Component {
  render() {
    const { supportedFiles, accept, handleMultipleFileDrop } = this.props;
    return (
      <>
        <Dropzone
          accept={{
            "text/csv": [".csv"],
          }}
          onDrop={(accepted, rejected) => {
            handleMultipleFileDrop(accepted, rejected);
          }}
          multiple={true}
        >
          {({ getRootProps, getInputProps }) => (
            <Card
              sx={{
                backgroundColor: "#2957A312",
                borderRadius: "20px",
                border: "2px solid #2957A3",
                borderStyle: "dashed",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                paddingLeft: 5,
                paddingRight: 5,
              }}
              {...getRootProps()}
            >
              <input
                data-testid="dropzone"
                {...getInputProps()}
                accept={accept}
              />
              <UploadFileIcon className="file-uploader-icon" />
              <Box className="flex-container-wrap">
                <p className="file-uploader-para1">
                  Drag and Drop files, or &nbsp;
                </p>
                <p className="file-uploader-para2">Browse</p>
              </Box>
              <p className="file-uploader-para3">
                Supported {supportedFiles} files only
              </p>
            </Card>
          )}
        </Dropzone>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    handleMultipleFileDrop: (accepted, rejected) =>
      api.handleMultipleFileDrop(accepted, rejected, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultipleFileUploader);
