import React, { Component } from "react";
import { connect } from "react-redux";

import { FormControlLabel } from "@mui/material";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

const IOSSwitchComponent = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, sides }) => ({
  width: sides.width,
  height: sides.height,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: sides.radius,
    height: sides.radius,
  },
  "& .MuiSwitch-track": {
    borderRadius: sides.height / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export class IOSSwitch extends Component {
  render() {
    const {
      checked,
      label,
      page,
      addType,
      addTypeVar,
      addTypeData,
      handleSwitchChange,
      size,
    } = this.props;
    let sides = {};
    switch (size) {
      case "small":
        sides = { width: 36.5, height: 21, radius: 16.5 };
        break;
      case "large":
        sides = { width: 42, height: 26, radius: 22 };
        break;
      case "medium":
        sides = { width: 38, height: 22, radius: 18 };
        break;
      default:
        sides = { width: 38, height: 22, radius: 18 };
        break;
    }
    return (
      <FormControlLabel
        label={label}
        labelPlacement="start"
        size={size}
        checked={checked}
        control={<IOSSwitchComponent sides={sides} sx={{ m: 1 }} />}
        onChange={(event) =>
          handleSwitchChange(
            event.target.checked,
            page,
            addType,
            addTypeVar,
            addTypeData
          )
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    handleSwitchChange: (checked, page, addType, addTypeVar, addTypeData) =>
      dispatch({
        type: "HANDLE_SWITCH_CHANGE",
        payload: { checked, page, addType, addTypeVar, addTypeData },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IOSSwitch);
