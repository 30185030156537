import * as m from "./methods";
import { initialState } from "./initialState";

export const reducers = (oldState = initialState, actionsAndParameters) => {
  let nState = JSON.parse(JSON.stringify(oldState));
  switch (actionsAndParameters.type) {
    case "SET_INIT_LOAD":
      m.setInitLoad(nState, actionsAndParameters);
      break;
    case "LOAD_API_SETTING":
      m.loadApiSetting(nState, actionsAndParameters);
      break;
    case "ADMIN_LOGIN":
      m.cognitoSignIn(nState, actionsAndParameters);
      break;
    case "FORCE_CHANGE_PASSWORD":
      m.forceChangePassword(nState, actionsAndParameters);
      break;
    case "USER_LOGIN":
      m.cognitoSignIn(nState, actionsAndParameters);
      break;
    case "GET_CODE_INITIATED":
      m.getCodeInitiated(nState, actionsAndParameters);
      break;
    case "PASSWORD_UPDATED":
      m.passwordUpdated(nState, actionsAndParameters);
      break;
    case "CLEAR_FORGOT_PASSWORD_VARIABLES":
      m.clearForgotPasswordVariables(nState, actionsAndParameters);
      break;
    case "LOG_OUT":
      m.logout(nState, actionsAndParameters);
      break;
    case "MOVE_TO_LOGIN":
      m.moveToLogin(nState, actionsAndParameters);
      break;
    case "UPDATE_AUTH_FORM":
      m.updateAuthForm(nState, actionsAndParameters);
      break;
    case "TOGGLE_LEFT_SIDE_BAR":
      m.toggleLeftSideBar(nState);
      break;
    case "CHANGE_PAGE":
      m.changePage(nState, actionsAndParameters);
      break;
    case "HANDLE_CHANGE_PAGE":
      m.handleChangePage(nState, actionsAndParameters);
      break;
    case "HANDLE_CHANGE_ROWS_PER_PAGE":
      m.handleChangeRowsPerPage(nState, actionsAndParameters);
      break;
    case "HANDLE_SINGLE_DROPDOWN_VALUE_CHANGE":
      m.handleSingleDropdownValueChange(nState, actionsAndParameters);
      break;
    case "HANDLE_MULTI_DROPDOWN_VALUE_CHANGE":
      m.handleMultiDropdownValueChange(nState, actionsAndParameters);
      break;
    case "OPEN_MODAL":
      m.openModal(nState, actionsAndParameters);
      break;
    case "CLOSE_MODAL":
      m.closeModal(nState, actionsAndParameters);
      break;
    case "STOP_LOADING":
      m.stopLoading(nState);
      break;
    case "DOWNLOAD_SIGNATURE_PDF":
      m.downloadSignaturePDF(nState, actionsAndParameters);
      break;
    case "HANDLE_FILE_DROP":
      m.handleFileDrop(nState, actionsAndParameters);
      break;
    case "HANDLE_DOC_FILE_DROP":
      m.handleDocFileDrop(nState, actionsAndParameters);
      break;
    case "DELETE_FILE":
      m.deleteFile(nState, actionsAndParameters);
      break;
    case "UPLOAD_DOCKET":
      m.uploadDocket(nState, actionsAndParameters);
      break;
    case "UPDATE_DOCKET_FORM":
      m.updateDocketForm(nState, actionsAndParameters);
      break;
    case "UPDATE_DOCKET_DATE_FORM":
      m.updateDocketDateForm(nState, actionsAndParameters);
      break;
    case "SUBMIT_DOCKET_FORM":
      m.submitDocketForm(nState, actionsAndParameters);
      break;
    case "SUBMIT_SIGN_HISTORY_FORM":
      m.submitSignHistoryForm(nState, actionsAndParameters);
      break;
    case "TOGGLE_CIRCULAR_LOADING":
      m.toggleCircularLoading(nState);
      break;
    case "LOAD_DOC_TYPE_DATA":
      m.loadDocTypeData(nState, actionsAndParameters);
      break;
    case "LOAD_SIGN_PARTY_DATA":
      m.loadSignPartyData(nState, actionsAndParameters);
      break;
    case "LOAD_DOC_SIGN_DATA":
      m.loadDocSignData(nState, actionsAndParameters);
      break;
    case "LOAD_DOC_SIGN_COORD_DATA":
      m.loadDocSignCoordData(nState, actionsAndParameters);
      break;
    case "LOAD_JAIL_EMAIL_DATA":
      m.loadJailEmailData(nState, actionsAndParameters);
      break;
    case "LOAD_JAIL_EMAIL_DOC_DATA":
      m.loadJailEmailDocData(nState, actionsAndParameters);
      break;
    case "OPEN_UPDATE_PARTY_SIGN_MODAL":
      m.openUpdatePartySignModal(nState, actionsAndParameters);
      break;
    case "OPEN_UPDATE_DOC_SIGN_MODAL":
      m.openUpdateDocSignModal(nState, actionsAndParameters);
      break;
    case "OPEN_UPDATE_JAIL_EMAIL_MODAL":
      m.openUpdateJailEmailModal(nState, actionsAndParameters);
      break;
    case "OPEN_UPDATE_JAIL_EMAIL_DOC_MODAL":
      m.openUpdateJailEmailDocModal(nState, actionsAndParameters);
      break;
    case "OPEN_DOCUMENT_TYPE_MODAL":
      m.openDocumentTypeModal(nState);
      break;
    case "OPEN_SIGN_PARTY_MODAL":
      m.openSignPartyModal(nState);
      break;
    case "OPEN_DOC_SIGN_MODAL":
      m.openDocSignModal(nState);
      break;
    case "OPEN_JAIL_EMAIL_MODAL":
      m.openJailEmailModal(nState);
      break;
    case "OPEN_JAIL_EMAIL_DOC_MODAL":
      m.openJailEmailDocModal(nState);
      break;
    case "OPEN_UPDATE_DOC_TYPE_MODAL":
      m.openUpdateDocTypeModal(nState, actionsAndParameters);
      break;
    case "CLOSE_DOCUMENT_TYPE_MODAL":
      m.closeDocumentTypeModal(nState);
      break;
    case "CLOSE_SIGN_PARTY_MODAL":
      m.closeSignPartyModal(nState);
      break;
    case "CLOSE_DOC_SIGN_MODAL":
      m.closeDocSignModal(nState);
      break;
    case "CLOSE_JAIL_EMAIL_MODAL":
      m.closeJailEmailModal(nState);
      break;
    case "CLOSE_JAIL_EMAIL_DOC_MODAL":
      m.closeJailEmailDocModal(nState);
      break;
    case "UPDATE_MAINTENANCE_FORM":
      m.updateMaintenanceForm(nState, actionsAndParameters);
      break;
    case "UPDATE_MAINTENANCE_CORD_FORM":
      m.updateMaintenanceCordForm(nState, actionsAndParameters);
      break;
    case "DELETE_DOC_SIGN_COORDINATES":
      m.deleteDocSignCoordinate(nState, actionsAndParameters);
      break;
    case "UPDATE_DOC_PARTY_DETAILS":
      m.updateDocPartyDetails(nState, actionsAndParameters);
      break;
    case "HANDLE_SWITCH_CHANGE":
      m.handleSwitchChange(nState, actionsAndParameters);
      break;
    case "MARK_COORDINATES":
      m.markCoordinates(nState, actionsAndParameters);
      break;
    case "SET_BOUNDARY":
      m.setBoundary(nState, actionsAndParameters);
      break;
    case "TOGGLE_CHECKBOX":
      m.toggleCheckBox(nState, actionsAndParameters);
      break;
    case "SUBMIT_DOCUMENT_TYPE":
      m.submitDocumentType(nState);
      break;
    case "SUBMIT_JAIL_EMAIL_PURPOSE":
      m.submitJailEmailPurpose(nState);
      break;
    case "SUBMIT_JAIL_EMAIL_DOC":
      m.submitJailEmailDocForm(nState);
      break;
    case "SUBMIT_SIGNATURE_PARTY":
      m.submitSignatureParty(nState);
      break;
    case "SUBMIT_DOCUMENT_SIGN":
      m.submitDocSignForm(nState);
      break;
    case "DELETE_DOC_TYPE":
      m.deleteDocType(nState, actionsAndParameters);
      break;
    case "UPDATE_SIGN_HISTORY_FORM":
      m.updateSignHistoryForm(nState, actionsAndParameters);
      break;
    case "UPDATE_JAIL_EMAIL_HISTORY_FORM":
      m.updateJailEmailHistoryForm(nState, actionsAndParameters);
      break;
    case "UPDATE_JAIL_EMAIL_DATE_FORM":
      m.updateJailEmailDateForm(nState, actionsAndParameters);
      break;
    case "SUBMIT_JAIL_EMAIL_HISTORY_FORM":
      m.submitJailEmailHistoryForm(nState, actionsAndParameters);
      break;
    case "UPDATE_SIGN_DATE_FORM":
      m.updateSignDateForm(nState, actionsAndParameters);
      break;
    case "RESEND_JAIL_EMAIL":
      m.resendJailEmail(nState, actionsAndParameters);
      break;
    default:
      break;
  }
  return nState;
};
