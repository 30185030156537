import { Button, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { Component } from "react";
import { connect } from "react-redux";

import * as api from "../../../Api/ApiCallbacks";

export class DocTypeModal extends Component {
  render() {
    const {
      id,
      documentTypeID,
      documentTypeName,
      documentTypeDesc,
      showModal,
      closeDocumentTypeModal,
      updateMaintenanceForm,
      formEnabled,
    } = this.props;
    return (
      <div>
        <Modal open={showModal} onClose={() => closeDocumentTypeModal()}>
          <Box className="modal-container">
            <Box className="doc-modal-container-form">
              {id === "" ? (
                <p className="doc-modal-container-para">
                  Enter Details to add new Document Type:
                </p>
              ) : (
                <p className="doc-modal-container-para">
                  Enter Details to update Document Type:
                </p>
              )}
              <Box className="doc-modal-container-form-1">
                <TextField
                  id="document-type-id"
                  fullWidth
                  label="Document Type ID"
                  type="search"
                  name="documentTypeID"
                  value={documentTypeID}
                  onChange={(event) =>
                    updateMaintenanceForm(event.target.name, event.target.value)
                  }
                />
                <TextField
                  id="document-type-name"
                  fullWidth
                  label="Document Type Name"
                  type="search"
                  name="documentTypeName"
                  value={documentTypeName}
                  onChange={(event) =>
                    updateMaintenanceForm(event.target.name, event.target.value)
                  }
                />
              </Box>
              <TextField
                id="document-type-desc"
                fullWidth
                label="Document Type Description"
                type="search"
                name="documentTypeDesc"
                value={documentTypeDesc}
                onChange={(event) =>
                  updateMaintenanceForm(event.target.name, event.target.value)
                }
              />
              <Box className="button-container">
                {id === "" ? (
                  <Button
                    disabled={!formEnabled}
                    onClick={() =>
                      this.props.submitDocTypeForm(
                        documentTypeID,
                        documentTypeName,
                        documentTypeDesc
                      )
                    }
                    variant="contained"
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    disabled={!formEnabled}
                    onClick={() =>
                      this.props.updateDocTypeForm(
                        id,
                        documentTypeID,
                        documentTypeName,
                        documentTypeDesc
                      )
                    }
                    variant="contained"
                  >
                    Update
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  id: state.DocumentTypeMaintenanceVariables.addDocumentType.id,
  documentTypeID:
    state.DocumentTypeMaintenanceVariables.addDocumentType.documentTypeID,
  documentTypeName:
    state.DocumentTypeMaintenanceVariables.addDocumentType.documentTypeName,
  documentTypeDesc:
    state.DocumentTypeMaintenanceVariables.addDocumentType.documentTypeDesc,
  formEnabled: state.DocumentTypeMaintenanceVariables.formEnabled,
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeDocumentTypeModal: () =>
      dispatch({
        type: "CLOSE_DOCUMENT_TYPE_MODAL",
      }),
    updateMaintenanceForm: (name, value) =>
      dispatch({
        type: "UPDATE_MAINTENANCE_FORM",
        payload: {
          page: "DocumentTypeMaintenanceVariables",
          addType: "addDocumentType",
          name,
          value,
        },
      }),
    submitDocTypeForm: (documentTypeID, documentTypeName, documentTypeDesc) =>
      api.submitDocTypeForm(
        {
          documentTypeID,
          documentTypeName,
          documentTypeDesc,
        },
        dispatch
      ),
    updateDocTypeForm: (
      id,
      documentTypeID,
      documentTypeName,
      documentTypeDesc
    ) =>
      api.updateDocTypeForm(
        {
          id,
          documentTypeID,
          documentTypeName,
          documentTypeDesc,
        },
        dispatch
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocTypeModal);
