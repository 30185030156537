import React, { Component } from "react";
import { connect } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";

class SingleValueDropDown extends Component {
  render() {
    const {
      selected,
      options,
      label,
      textFieldLabel,
      page,
      addType,
      addTypeData,
      handleSingleDropdownValueChange,
    } = this.props;

    return (
      <Autocomplete
        fullWidth
        options={options}
        value={selected}
        getOptionLabel={(option) => option[label]}
        renderOption={(props, option, { selected }) => (
          <li {...props}>{option[label]}</li>
        )}
        isOptionEqualToValue={(option, value) => {
          return option[label] === value[label];
        }}
        onChange={(event, newValue) => {
          handleSingleDropdownValueChange(newValue, page, addType, addTypeData);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={textFieldLabel}
            placeholder={textFieldLabel}
          />
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    handleSingleDropdownValueChange: (val, page, addType, addTypeData) =>
      dispatch({
        type: "HANDLE_SINGLE_DROPDOWN_VALUE_CHANGE",
        payload: { page, addType, addTypeData, val },
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleValueDropDown);
