import React, { Component } from "react";
import { connect } from "react-redux";

import { Box } from "@mui/system";
import { Card, CardActionArea, CardContent } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DocTypeModal from "./DocTypeModal";
import SignPartyModal from "./SignPartyModal";
import DeleteIcon from "@mui/icons-material/Delete";

import * as api from "../../../Api/ApiCallbacks";
import JailEmailModal from "./JailEmailModal";
import JailEmailDocModal from "./JailEmailDocModal";

export class Maintenance extends Component {
  componentDidMount() {}
  render() {
    const {
      documentTypes,
      signatureParties,
      jailEmails,
      jailEmailDocs,
      openDocumentTypeModal,
      openSignPartyModal,
      openJailEmailModal,
    } = this.props;
    return (
      <Box className="maintenance-container">
        <DocTypeModal showModal={this.props.docShowModal} />
        <SignPartyModal showModal={this.props.signShowModal} />
        <JailEmailModal showModal={this.props.jailEmailShowModal} />
        <JailEmailDocModal showModal={this.props.jailEmailDocsShowModal} />
        <Box label="documentCard" className="documents-container">
          <p className="maintenance-header">Document Type Maintenance</p>
          <Box className="cards-container">
            <Card className="card-container">
              <CardActionArea onClick={() => openDocumentTypeModal()}>
                <CardContent className="flex-container">
                  <AddIcon sx={{ fontSize: 100, color: "grey" }} />
                </CardContent>
              </CardActionArea>
            </Card>
            {documentTypes?.map((document) => {
              return (
                <Card key={document.id} className="card-container">
                  <CardActionArea
                    onClick={() => this.props.openUpdateDocTypeModal(document)}
                  >
                    <CardContent className="card-content-container">
                      <div className="card-content-delete-icon">
                        <DeleteIcon
                          sx={{ color: "grey" }}
                          onClick={(event) => {
                            event.stopPropagation();
                            this.props.deleteDocType(document.id);
                          }}
                        />
                      </div>
                      <p className="document-card-container-para">
                        Document Type
                      </p>
                      <p className="card-content-para">
                        <b>ID: </b>
                        {document.documentTypeID}
                      </p>
                      <p className="card-content-para">
                        <b>Name: </b>
                        {document.documentTypeName}
                      </p>
                      <p className="card-content-para">
                        <b>Description: </b>
                        {document.documentTypeDesc}
                      </p>
                    </CardContent>
                  </CardActionArea>
                </Card>
              );
            })}
          </Box>
        </Box>
        <Box className="documents-container">
          <p className="maintenance-header">Signature Party Maintenance</p>
          <Box className="cards-container">
            <Card className="signature-card-container">
              <CardActionArea onClick={() => openSignPartyModal()}>
                <CardContent className="flex-container">
                  <AddIcon sx={{ fontSize: 50, color: "grey" }} />
                </CardContent>
              </CardActionArea>
            </Card>
            {signatureParties.map((partySign) => {
              return (
                <Card key={partySign.id} className="signature-card-container">
                  <CardActionArea
                    onClick={() =>
                      this.props.openUpdatePartySignModal(partySign)
                    }
                  >
                    <CardContent className="card-content-container">
                      <div className="card-content-delete-icon">
                        <DeleteIcon
                          sx={{ color: "grey" }}
                          onClick={(event) => {
                            event.stopPropagation();
                            this.props.deleteSignParty(partySign.id);
                          }}
                        />
                      </div>
                      <p className="document-card-container-para">
                        Signature Party
                      </p>
                      <p className="card-content-para">
                        <b>Name: </b>
                        {partySign.signaturePartyName}
                      </p>
                    </CardContent>
                  </CardActionArea>
                </Card>
              );
            })}
          </Box>
        </Box>
        {/* <Box className="documents-container">
          <p className="maintenance-header">Document Signature Maintenance</p>
          <Box className="cards-container">
            <Card className="doc-sign-card-container">
              <CardActionArea onClick={() => openDocSignModal()}>
                <CardContent className="flex-container">
                  <AddIcon sx={{ fontSize: 50, color: "grey" }} />
                </CardContent>
              </CardActionArea>
            </Card>
            {docSignatures?.map((docSign) => {
              return (
                <Card key={docSign.id} className="doc-sign-card-container">
                  <CardActionArea
                    onClick={() => this.props.openUpdateDocSignModal(docSign)}
                  >
                    <CardContent className="card-content-container">
                      <div className="card-content-delete-icon">
                        <DeleteIcon
                          sx={{ color: "grey" }}
                          onClick={(event) => {
                            event.stopPropagation();
                            this.props.deleteDocSign(docSign.id);
                          }}
                        />
                      </div>
                      <p className="document-card-container-para">
                        Document Signature Template
                      </p>
                      <p className="card-content-para">
                        <b>Document Type Name: </b>
                        {docSign.documentType.documentTypeName}
                      </p>
                      <p className="card-content-para">
                        <b>Signature Party Name: </b>
                        {docSign.signatureParty.signaturePartyName}
                      </p>
                      <p className="card-content-para">
                        <b>Signature Coordiate: </b>
                      </p>
                      <p className="card-content-para">
                        <b>x0-coordinate: </b>
                        {docSign.x0}
                      </p>
                      <p className="card-content-para">
                        <b>y0-coordinate: </b>
                        {docSign.y0}
                      </p>
                      <p className="card-content-para">
                        <b>x1-coordinate: </b>
                        {docSign.x1}
                      </p>
                      <p className="card-content-para">
                        <b>y1-coordinate: </b>
                        {docSign.y1}
                      </p>
                      <p className="card-content-para">
                        <b>Page no.: </b>
                        {docSign.pageNo}
                      </p>
                      <p className="card-content-para">
                        <b>Signature Required: </b>
                        {docSign.isRequired ? "Yes" : "No"}
                      </p>
                    </CardContent>
                  </CardActionArea>
                </Card>
              );
            })}
          </Box>
        </Box> */}
        <Box className="documents-container">
          <p className="maintenance-header">
            Jail Email Purpose Documents Maintenance
          </p>
          <Box className="cards-container">
            <Card className="email-purpose-doc-card-container">
              <CardActionArea
                onClick={() => this.props.openJailEmailDocModal()}
              >
                <CardContent className="flex-container">
                  <AddIcon sx={{ fontSize: 50, color: "grey" }} />
                </CardContent>
              </CardActionArea>
            </Card>
            {jailEmailDocs?.map((jailEmailDoc) => {
              return (
                <Card
                  key={jailEmailDoc.id}
                  className="email-purpose-doc-card-container"
                >
                  <CardActionArea
                    onClick={() =>
                      this.props.openUpdateJailEmailDocModal(jailEmailDoc)
                    }
                  >
                    <CardContent className="card-content-container">
                      <div className="card-content-delete-icon">
                        <DeleteIcon
                          sx={{ color: "grey" }}
                          onClick={(event) => {
                            event.stopPropagation();
                            this.props.deleteJailEmailDoc(jailEmailDoc.id);
                          }}
                        />
                      </div>
                      <p className="document-card-container-para">
                        Purpose Documents
                      </p>
                      <p className="card-content-para">
                        <b>Purpose: </b>
                        {jailEmailDoc.subjectPurpose}
                      </p>
                      <div className="card-content-para">
                        <p className="card-content-para">
                          <b>Documents: </b>
                        </p>
                        <ul>
                          {jailEmailDoc.docs.map((doc, index) => {
                            return (
                              <li key={index} className="card-content-para">
                                {doc.documentTypeID}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </CardContent>
                  </CardActionArea>
                </Card>
              );
            })}
          </Box>
        </Box>
        <Box className="documents-container">
          <p className="maintenance-header">Jail Email Purpose Maintenance</p>
          <Box className="cards-container">
            <Card className="email-purpose-card-container">
              <CardActionArea onClick={() => openJailEmailModal()}>
                <CardContent className="flex-container">
                  <AddIcon sx={{ fontSize: 50, color: "grey" }} />
                </CardContent>
              </CardActionArea>
            </Card>
            {jailEmails?.map((jailEmail) => {
              return (
                <Card
                  key={jailEmail.id}
                  className="email-purpose-card-container"
                >
                  <CardActionArea
                    onClick={() =>
                      this.props.openUpdateJailEmailModal(jailEmail)
                    }
                  >
                    <CardContent className="card-content-container">
                      <div className="card-content-delete-icon">
                        <DeleteIcon
                          sx={{ color: "grey" }}
                          onClick={(event) => {
                            event.stopPropagation();
                            this.props.deleteJailEmail(jailEmail.id);
                          }}
                        />
                      </div>
                      <p className="document-card-container-para">
                        Signature Party
                      </p>
                      <p className="card-content-para">
                        <b>Purpose: </b>
                        {jailEmail.subjectPurpose.subjectPurpose}
                      </p>
                      <p className="card-content-para">
                        <b>Email ID: </b>
                        {jailEmail.emailID}
                      </p>
                      <p className="card-content-para">
                        <b>Email Subject: </b>
                        {jailEmail.emailSubject}
                      </p>
                      <p className="card-content-para">
                        <b>Email Body: </b>
                        {jailEmail.emailBody}
                      </p>
                    </CardContent>
                  </CardActionArea>
                </Card>
              );
            })}
          </Box>
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  documentTypes: state.DocumentTypeMaintenanceVariables.documentTypes,
  signatureParties: state.SignaturePartyMaintenanceVariables.signatureParties,
  docShowModal: state.DocumentTypeMaintenanceVariables.showModal,
  signShowModal: state.SignaturePartyMaintenanceVariables.showModal,
  docSignatures: state.DocumentSignatureMaintenanceVariables.docSignatures,
  docSignShowModal: state.DocumentSignatureMaintenanceVariables.showModal,
  jailEmails: state.JailEmailMaintenanceVariables.jailEmails,
  jailEmailShowModal: state.JailEmailMaintenanceVariables.showModal,
  jailEmailDocs: state.JailEmailDocumentMaintenanceVariables.jailEmailDocs,
  jailEmailDocsShowModal: state.JailEmailDocumentMaintenanceVariables.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    openDocumentTypeModal: () => dispatch({ type: "OPEN_DOCUMENT_TYPE_MODAL" }),
    openSignPartyModal: () => dispatch({ type: "OPEN_SIGN_PARTY_MODAL" }),
    openDocSignModal: () => dispatch({ type: "OPEN_DOC_SIGN_MODAL" }),
    openJailEmailModal: () => dispatch({ type: "OPEN_JAIL_EMAIL_MODAL" }),
    openJailEmailDocModal: () =>
      dispatch({ type: "OPEN_JAIL_EMAIL_DOC_MODAL" }),
    openUpdateDocTypeModal: (value) =>
      dispatch({ type: "OPEN_UPDATE_DOC_TYPE_MODAL", payload: value }),
    openUpdatePartySignModal: (value) =>
      dispatch({ type: "OPEN_UPDATE_PARTY_SIGN_MODAL", payload: value }),
    openUpdateDocSignModal: (value) =>
      dispatch({ type: "OPEN_UPDATE_DOC_SIGN_MODAL", payload: value }),
    openUpdateJailEmailModal: (value) =>
      dispatch({ type: "OPEN_UPDATE_JAIL_EMAIL_MODAL", payload: value }),
    openUpdateJailEmailDocModal: (value) =>
      dispatch({ type: "OPEN_UPDATE_JAIL_EMAIL_DOC_MODAL", payload: value }),
    deleteDocType: (id) => api.deleteDocType(id, dispatch),
    deleteSignParty: (id) => api.deleteSignParty(id, dispatch),
    deleteDocSign: (id) => api.deleteDocSign(id, dispatch),
    deleteJailEmail: (id) => api.deleteJailEmail(id, dispatch),
    deleteJailEmailDoc: (id) => api.deleteJailEmailDoc(id, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Maintenance);
