import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  TablePagination,
  Box,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import customParseFormat from "dayjs/plugin/customParseFormat";

import JailEmailHistoryModal from "./JailEmailHistoryModal";
import CircularProgressLoader from "./CircularProgressLoader";

import * as api from "../../Api/ApiCallbacks";
import SingleValueDropDown from "./SingleValueDropDown";

class JailEmailHistory extends Component {
  render() {
    dayjs.extend(customParseFormat);
    const dateFormat = "MM/DD/YYYY";
    const { RangePicker } = DatePicker;
    const {
      page,
      rowsPerPage,
      showModal,
      jailEmails,
      handleChangeRowsPerPage,
      handleChangePage,
      caseNbr,
      emailPurpose,
      startDate,
      endDate,
      formEnabled,
      openModal,
      subjectPurposes,
    } = this.props;
    let i = 0;
    return (
      <Box className="page-container">
        <JailEmailHistoryModal showModal={showModal} />
        <Box className="form-container">
          <TextField
            id="case-no"
            fullWidth
            label="Case Number"
            type="search"
            name="caseNbr"
            value={caseNbr}
            onChange={(event) =>
              this.props.updateJailEmailHistoryForm(
                event.target.name,
                event.target.value
              )
            }
          />
          <SingleValueDropDown
            selected={emailPurpose}
            options={subjectPurposes}
            label="subjectPurpose"
            textFieldLabel="Subject Purpose"
            page="JailEmailHistoryVariables"
            addType="formData"
            addTypeData="emailPurpose"
          />
          <RangePicker
            className="range-picker"
            onChange={(dates, dateString) =>
              this.props.updateJailEmailDateForm(dates, dateString)
            }
            format={dateFormat}
            value={[
              startDate !== "" ? dayjs(startDate, dateFormat) : null,
              endDate !== "" ? dayjs(endDate, dateFormat) : null,
            ]}
          />
        </Box>
        <Box className="button-container">
          <Button
            disabled={!formEnabled}
            onClick={() =>
              this.props.submitJailEmailHistoryForm(
                caseNbr,
                emailPurpose,
                startDate,
                endDate
              )
            }
            variant="contained"
          >
            Search
          </Button>
        </Box>
        {this.props.isLoading ? (
          <Box className="flex-container">
            <CircularProgressLoader />
          </Box>
        ) : (
          <>
            {jailEmails.length === 0 ? (
              <Box>
                <Typography>No jail emails available</Typography>
              </Box>
            ) : (
              <Paper>
                <TableContainer className="table-container">
                  <Table
                    stickyHeader
                    aria-label="signature-history-table"
                    className="table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>S. No.</TableCell>
                        <TableCell>Case No.</TableCell>
                        <TableCell>Email Purpose</TableCell>
                        <TableCell>Sent Datetime</TableCell>
                        <TableCell>Mail Sent</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {jailEmails
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((jailEmail) => {
                          let sNo = rowsPerPage * page + i + 1;
                          i = i + 1;
                          return (
                            <TableRow
                              hover
                              onClick={() =>
                                openModal(
                                  jailEmail.id,
                                  jailEmail.caseNbr,
                                  jailEmail.recipientEmailID,
                                  jailEmail.emailPurpose,
                                  jailEmail.emailBody,
                                  jailEmail.documents,
                                  jailEmail.sendingDatetime,
                                  jailEmail.isMailSent,
                                  jailEmail.runStatus,
                                  jailEmail.isResended
                                )
                              }
                              role="checkbox"
                              tabIndex={-1}
                              key={sNo}
                            >
                              <TableCell>{sNo}</TableCell>
                              <TableCell>{jailEmail.caseNbr}</TableCell>
                              <TableCell>{jailEmail.emailPurpose}</TableCell>
                              <TableCell>
                                {jailEmail.sendingDatetime === null
                                  ? "Not Sent Yet"
                                  : api.convertDatetime(
                                      jailEmail.sendingDatetime
                                    )}
                              </TableCell>
                              <TableCell>
                                {jailEmail.runStatus === "FAILED" ? (
                                  <Button
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      this.props.resendJailEmail(jailEmail.id);
                                    }}
                                    variant="contained"
                                  >
                                    Re Send
                                  </Button>
                                ) : jailEmail.runStatus === "SUCCESS" ? (
                                  "SENT"
                                ) : (
                                  "SENDING"
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={jailEmails.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(event, newPage) =>
                    handleChangePage(event, newPage)
                  }
                  onRowsPerPageChange={(event) =>
                    handleChangeRowsPerPage(event.target.value)
                  }
                />
              </Paper>
            )}
          </>
        )}
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  page: state.JailEmailHistoryVariables.page,
  rowsPerPage: state.JailEmailHistoryVariables.rowsPerPage,
  showModal: state.ModalDetailsPageVariables.showModal,
  jailEmails: state.JailEmailHistoryVariables.jailEmails,
  caseNbr: state.JailEmailHistoryVariables.formData.caseNbr,
  emailPurpose: state.JailEmailHistoryVariables.formData.emailPurpose,
  startDate: state.JailEmailHistoryVariables.formData.startDate,
  endDate: state.JailEmailHistoryVariables.formData.endDate,
  subjectPurposes: state.JailEmailDocumentMaintenanceVariables.jailEmailDocs,
  formEnabled: state.JailEmailHistoryVariables.formEnabled,
  isLoading: state.CircularProgressVariables.isLoading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleChangePage: (event, newPageNumber) => {
      dispatch({
        type: "HANDLE_CHANGE_PAGE",
        payload: {
          page: "JailEmailHistoryVariables",
          event,
          newPageNumber,
        },
      });
    },
    handleChangeRowsPerPage: (val) =>
      dispatch({
        type: "HANDLE_CHANGE_ROWS_PER_PAGE",
        payload: { page: "JailEmailHistoryVariables", data: val },
      }),
    openModal: (
      id,
      caseNbr,
      recipientEmailID,
      emailPurpose,
      emailBody,
      documents,
      sendingDatetime,
      isMailSent,
      runStatus,
      isResended
    ) =>
      dispatch({
        type: "OPEN_MODAL",
        payload: {
          page: "JailEmailHistoryVariables",
          data: {
            id,
            caseNbr,
            recipientEmailID,
            emailPurpose,
            emailBody,
            documents,
            sendingDatetime,
            isMailSent,
            runStatus,
            isResended,
          },
        },
      }),
    updateJailEmailHistoryForm: (name, value) =>
      dispatch({
        type: "UPDATE_JAIL_EMAIL_HISTORY_FORM",
        payload: { name, value },
      }),
    updateJailEmailDateForm: (dates, dateString) =>
      dispatch({
        type: "UPDATE_JAIL_EMAIL_DATE_FORM",
        payload: { dates, dateString },
      }),
    submitJailEmailHistoryForm: (caseNbr, emailPurpose, startDate, endDate) =>
      api.submitJailEmailHistoryForm(
        { caseNbr, emailPurpose, startDate, endDate },
        dispatch
      ),
    resendJailEmail: (id) => api.resendJailEmail(id, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JailEmailHistory);
