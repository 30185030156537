import React, { Component } from "react";

import { connect } from "react-redux";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Link, Navigate } from "react-router-dom";
import logo from "../../Images/quicket-logo.svg";
import * as api from "../../Api/ApiCallbacks";

class SetNewPassword extends Component {
  render() {
    const {
      username,
      password,
      newPassword,
      confirmNewPassword,
      isAuthenticated,
      isForceChangePassword,
    } = this.props;
    if (isAuthenticated) {
      return <Navigate to="/home" replace={true} />;
    }
    if (username === "" || isForceChangePassword === false) {
      return <Navigate to="/login" replace={true} />;
    }
    return (
      <div className="auth-page-container">
        <Box className="login-container">
          <Box className="login-form-container">
            <img src={logo} alt="" style={{ marginBottom: 15 }} />
            <Typography
              variant="h6"
              sx={{
                color: "#4D5B7C",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              Login to Akron City, OHIO
            </Typography>
            <TextField
              required
              fullWidth
              disabled
              id="username"
              label="Email"
              name="username"
              autoComplete="username"
              value={username}
              autoFocus
              onChange={(event) => {
                this.props.updateAuthForm(
                  event.target.name,
                  event.target.value
                );
              }}
            />
            <TextField
              required
              fullWidth
              name="newPassword"
              label="New Password"
              type="password"
              id="new-password"
              autoComplete="new-password"
              onChange={(event) => {
                this.props.updateAuthForm(
                  event.target.name,
                  event.target.value
                );
              }}
            />
            <TextField
              required
              fullWidth
              name="confirmNewPassword"
              label="Confirm Password"
              type="password"
              id="confirm-new-password"
              autoComplete="confirm-new-password"
              onChange={(event) => {
                this.props.updateAuthForm(
                  event.target.name,
                  event.target.value
                );
              }}
            />
            <Link
              onClick={() => this.props.moveToLogin()}
              className="link-url"
              style={{ justifyContent: "flex-end" }}
              to="/login"
            >
              Already a user?
            </Link>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={(e) => {
                e.preventDefault();
                this.props.forceChangePassword(
                  username,
                  password,
                  newPassword,
                  confirmNewPassword
                );
              }}
            >
              Set New Password
            </Button>
          </Box>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  username: state.LoginPageVariables.username,
  password: state.LoginPageVariables.password,
  newPassword: state.SetNewPasswordPageVariable.newPassword,
  confirmNewPassword: state.SetNewPasswordPageVariable.confirmNewPassword,
  isAuthenticated: state.UserDataVariables.isAuthenticated,
  isForceChangePassword: state.LoginPageVariables.isForceChangePassword,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateAuthForm: (name, value) =>
      dispatch({
        type: "UPDATE_AUTH_FORM",
        payload: {
          name: name,
          value: value,
          page: "SetNewPasswordPageVariable",
        },
      }),
    moveToLogin: () =>
      dispatch({
        type: "MOVE_TO_LOGIN",
      }),
    forceChangePassword: (
      username,
      password,
      newPassword,
      confirmNewPassword
    ) =>
      api.forceChangePassword(
        username,
        password,
        newPassword,
        confirmNewPassword,
        dispatch
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetNewPassword);
