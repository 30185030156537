export const initialState = {
  apiLocationLoaded: false,
  AppConfigs: {
    apiEndpoint: null,
    userPoolId: null,
    userPoolWebClientId: null,
    region: null,
  },
  initLoad: true,
  AdminLogin: {
    isAuthenticated: false,
    listItems: [
      "Home",
      "Docket Upload",
      "Docket History",
      "Document Maintenance",
      "Signature Maintenance",
      "e-Signature",
      "Jail Email",
    ],
  },
  ClerkLogin: {
    isAuthenticated: false,
    listItems: [
      "Home",
      "Docket Upload",
      "Docket History",
      "e-Signature",
      "Jail Email",
    ],
  },
  ForgotPasswordPageVariables: {
    username: "",
    newPassword: "",
    code: "",
    getCodeInitiated: false,
  },
  SetNewPasswordPageVariable: {
    newPassword: "",
    confirmNewPassword: "",
    user: null,
  },
  LoginPageVariables: {
    username: "",
    password: "",
    isForceChangePassword: false,
  },
  SignUpPageVariables: {
    username: "",
    email: "",
    password: "",
  },
  LeftSideBarVariables: {
    open: false,
  },
  CurrentPage: "Home",
  UserDataVariables: {
    userGroup: null,
    listItems: [],
    isAuthenticated: false,
    username: "",
    jwtToken: "",
  },
  MultiValueDropdownVariables: {
    selectedValues: [],
  },
  ModalDetailsPageVariables: {
    showModal: false,
    modalKey: "",
    modalDetails: {},
  },
  DocumentTypeMaintenanceVariables: {
    showModal: false,
    formEnabled: false,
    addDocumentType: {
      id: "",
      documentTypeID: "",
      documentTypeName: "",
      documentTypeDesc: "",
    },
    documentTypes: [],
  },
  SignaturePartyMaintenanceVariables: {
    showModal: false,
    formEnabled: false,
    addSignatureParty: {
      id: "",
      signaturePartyName: "",
    },
    signatureParties: [],
  },
  DocumentSignatureMaintenanceVariables: {
    showCanvas: false,
    formEnabled: false,
    addDocSign: {
      fileUrl: null,
      isRequired: false,
      isInitial: false,
      id: "",
      signatureHintText: "",
      documentType: null,
      signatureParty: null,
      boundaryDetails: null,
      coordinates: {},
      deletedIDs: [],
    },
    docSignatures: {},
  },
  JailEmailDocumentMaintenanceVariables: {
    showModal: false,
    formEnabled: false,
    addEmailPurposeDoc: {
      id: "",
      subjectPurpose: "",
      docs: [],
    },
    jailEmailDocs: [],
  },
  JailEmailMaintenanceVariables: {
    showModal: false,
    formEnabled: false,
    addEmailPurpose: {
      id: "",
      emailID: "",
      subjectPurpose: null,
      emailSubject: "",
      emailBody: "",
    },
    jailEmails: [],
  },
  JailEmailHistoryVariables: {
    page: 0,
    rowsPerPage: 10,
    showModal: false,
    formEnabled: false,
    formData: {
      caseNbr: "",
      emailPurpose: null,
      startDate: "",
      endDate: "",
    },
    jailEmails: [],
  },
  CircularProgressVariables: {
    isLoading: false,
  },
  DocketHistoryPageVariables: {
    page: 0,
    rowsPerPage: 10,
    showModal: false,
    caseNbr: "",
    startDate: "",
    endDate: "",
    firstName: "",
    middleName: "",
    lastName: "",
    dockets: [],
  },
  DocketUploaderPageVariables: {
    acceptedFilesKey: [],
    uploadedDocket: { docketKey: [], docketData: {} },
    rejectedFiles: [],
    parsedData: {},
    errorMessage: "",
    uploadedFiles: {},
  },
  SignatureHistoryPageVariables: {
    page: 0,
    rowsPerPage: 10,
    caseNbr: "",
    startDate: "",
    endDate: "",
    firstName: "",
    middleName: "",
    lastName: "",
    formEnabled: false,
    signaturesData: [],
  },
  AppDownloaderPageVariables: {
    page: 0,
    rowsPerPage: 5,
    appDownloadVersionData: [
      {
        id: 1,
        versionNo: "1.0.1",
        releasedOn: "2023/05/09",
      },
      {
        id: 2,
        versionNo: "1.0.1",
        releasedOn: "2023/05/09",
      },
      {
        id: 3,
        versionNo: "1.0.1",
        releasedOn: "2023/05/09",
      },
      {
        id: 4,
        versionNo: "1.0.1",
        releasedOn: "2023/05/09",
      },
      {
        id: 5,
        versionNo: "1.0.1",
        releasedOn: "2023/05/09",
      },
      {
        id: 6,
        versionNo: "1.0.1",
        releasedOn: "2023/05/09",
      },
    ],
  },
};
