import { toast } from "react-hot-toast";

export const loadApiSetting = (state, actionsAndParameters) => {
  const { apiEndpoint, userPoolId, userPoolWebClientId, region } =
    actionsAndParameters.payload;
  state.AppConfigs.apiEndpoint = apiEndpoint;
  state.AppConfigs.userPoolId = userPoolId;
  state.AppConfigs.userPoolWebClientId = userPoolWebClientId;
  state.AppConfigs.region = region;
  state.apiLocationLoaded = true;
};

export const setInitLoad = (state) => {
  state.initLoad = false;
};

export const toggleLeftSideBar = (state) => {
  state.LeftSideBarVariables.open = !state.LeftSideBarVariables.open;
};

export const changePage = (state, actionsAndParameters) => {
  state.CurrentPage = actionsAndParameters.payload;
};

export const handleChangePage = (state, actionsAndParameters) => {
  const { page, newPageNumber } = actionsAndParameters.payload;
  state[page].page = newPageNumber;
};

export const handleChangeRowsPerPage = (state, actionsAndParameters) => {
  const { page, data } = actionsAndParameters.payload;
  state[page].rowsPerPage = data;
  state[page].page = 0;
};

export const handleSingleDropdownValueChange = (
  state,
  actionsAndParameters
) => {
  const { val, page, addType, addTypeData } = actionsAndParameters.payload;
  state[page][addType][addTypeData] = val;
  if (
    page === "DocumentSignatureMaintenanceVariables" &&
    addType === "addDocSign" &&
    addTypeData === "documentType" &&
    val !== null
  ) {
    if (
      state[page].docSignatures[val?.documentTypeID] === undefined ||
      state[page].docSignatures[val?.documentTypeID] === null
    ) {
      state[page][addType].coordinates = {};
    } else {
      state[page][addType].coordinates =
        state[page].docSignatures[val?.documentTypeID];
    }
  }
  if (
    page === "DocumentSignatureMaintenanceVariables" &&
    addType === "addDocSign" &&
    addTypeData === "documentType" &&
    val === null
  ) {
    state[page][addType].coordinates = {};
  }
  switch (page) {
    case "DocumentTypeMaintenanceVariables":
      checkDocTypeForm();
      break;
    case "SignaturePartyMaintenanceVariables":
      checkSignPartyForm();
      break;
    // case "DocumentSignatureMaintenanceVariables":
    //   checkDocSignForm(state, addType, page);
    //   break;
    case "JailEmailDocumentMaintenanceVariables":
      checkJailEmailDocForm(state, addType, page);
      break;
    case "JailEmailMaintenanceVariables":
      checkJailEmailForm(state, addType, page);
      break;
    case "JailEmailHistoryVariables":
      checkJailEmailHistoryForm(state);
      break;
    default:
      break;
  }
};

export const handleMultiDropdownValueChange = (state, actionsAndParameters) => {
  const { val, page, addType, addTypeData } = actionsAndParameters.payload;
  state[page][addType][addTypeData] = val.slice(0);
  switch (page) {
    case "DocumentTypeMaintenanceVariables":
      checkDocTypeForm(state, addType, page);
      break;
    case "SignaturePartyMaintenanceVariables":
      checkSignPartyForm(state, addType, page);
      break;
    // case "DocumentSignatureMaintenanceVariables":
    //   checkDocSignForm(state, addType, page);
    //   break;
    case "JailEmailDocumentMaintenanceVariables":
      checkJailEmailDocForm(state, addType, page);
      break;
    case "JailEmailMaintenanceVariables":
      checkJailEmailForm(state, addType, page);
      break;
    default:
      break;
  }
};

export const openModal = (state, actionsAndParameters) => {
  const { page, data } = actionsAndParameters.payload;
  state.ModalDetailsPageVariables.showModal = true;
  if (
    page === "DocketUploaderPageVariables" ||
    page === "DocketHistoryPageVariables"
  ) {
    state.ModalDetailsPageVariables.modalKey = actionsAndParameters.payload.key;
    state.ModalDetailsPageVariables.modalDetails[
      actionsAndParameters.payload.key
    ] = data;
  } else {
    state.ModalDetailsPageVariables.modalDetails = data;
  }
};

export const closeModal = (state, actionsAndParameters) => {
  const { page } = actionsAndParameters.payload;
  state.ModalDetailsPageVariables.showModal = false;
  if (page === "DocketUploaderPageVariables") {
    state.ModalDetailsPageVariables.modalKey = "";
    state.ModalDetailsPageVariables.modalDetails = [];
  } else {
    state.ModalDetailsPageVariables.modalDetails = {};
  }
};

export const stopLoading = (state) => {
  state.CircularProgressVariables.isLoading = false;
};

export const downloadSignaturePDF = (state, actionsAndParameters) => {};

export const cognitoSignIn = (state, actionsAndParameters) => {
  const { userGroup, username, jwtToken } = actionsAndParameters.payload;
  state.UserDataVariables.isAuthenticated = true;
  state.UserDataVariables.username = username;
  state.UserDataVariables.jwtToken = jwtToken;
  state.UserDataVariables.userGroup = userGroup;
  if (state.LoginPageVariables.isForceChangePassword) {
    state.SetNewPasswordPageVariable.newPassword = "";
    state.SetNewPasswordPageVariable.confirmNewPassword = "";
    state.SetNewPasswordPageVariable.user = null;
    state.LoginPageVariables.isForceChangePassword = false;
  }
  if (userGroup === "admin" || userGroup === "admins") {
    state.AdminLogin.isAuthenticated = true;
    state.UserDataVariables.listItems = state.AdminLogin.listItems.slice(0);
  } else {
    state.ClerkLogin.isAuthenticated = true;
    state.UserDataVariables.listItems = state.ClerkLogin.listItems.slice(0);
  }
};

export const forceChangePassword = (state, actionsAndParameters) => {
  state.LoginPageVariables.isForceChangePassword = true;
};

export const getCodeInitiated = (state, actionsAndParameters) => {
  state.ForgotPasswordPageVariables.getCodeInitiated = true;
};

export const passwordUpdated = (state, actionsAndParameters) => {
  state.ForgotPasswordPageVariables.username = "";
  state.ForgotPasswordPageVariables.newPassword = "";
  state.ForgotPasswordPageVariables.code = "";
  state.ForgotPasswordPageVariables.getCodeInitiated = false;
};

export const clearForgotPasswordVariables = (state, actionsAndParameters) => {
  state.ForgotPasswordPageVariables.username = "";
  state.ForgotPasswordPageVariables.newPassword = "";
  state.ForgotPasswordPageVariables.code = "";
  state.ForgotPasswordPageVariables.getCodeInitiated = false;
};

export const logout = (state, actionsAndParameters) => {
  state.UserDataVariables.isAuthenticated = false;
  state.UserDataVariables.userData = null;
  state.UserDataVariables.jwtToken = null;
  if (
    state.UserDataVariables.userGroup === "admin" ||
    state.UserDataVariables.userGroup === "admins"
  ) {
    state.AdminLogin.isAuthenticated = false;
  } else {
    state.ClerkLogin.isAuthenticated = false;
  }
  state.UserDataVariables.listItems = [];
  state.UserDataVariables.userGroup = null;
  window.location.reload();
};

export const moveToLogin = (state, actionsAndParameters) => {
  state.LoginPageVariables.isForceChangePassword = false;
  state.LoginPageVariables.username = "";
  state.LoginPageVariables.password = "";
};

export const updateAuthForm = (state, actionsAndParameters) => {
  const { page, name, value } = actionsAndParameters.payload;
  state[page][name] = value;
};

export const handleFileDrop = (state, actionsAndParameters) => {
  const { name, data, headers } = actionsAndParameters.payload;
  if (name in state.DocketUploaderPageVariables.parsedData === true) {
    state.DocketUploaderPageVariables.rejectedFiles.push(name);
    toast.error("File already exists!!!", { id: 1 });
  } else {
    let parsedData = [];
    for (let i = 0; i < data.length; i++) {
      let nonEmptyrow = true;
      for (let j = 0; j < headers.length; j++) {
        if (data[i][headers[j]] !== "") {
          nonEmptyrow = false;
        }
      }
      if (nonEmptyrow === false) {
        parsedData.push(data[i]);
      }
    }
    state.DocketUploaderPageVariables.acceptedFilesKey.push(name);
    state.DocketUploaderPageVariables.parsedData = {
      ...state.DocketUploaderPageVariables.parsedData,
      [name]: parsedData,
    };
  }
};

export const handleDocFileDrop = (state, actionsAndParameters) => {
  state.DocumentSignatureMaintenanceVariables.addDocSign.fileUrl =
    actionsAndParameters.payload;
};

export const deleteFile = (state, actionsAndParameters) => {
  const { filePath } = actionsAndParameters.payload;
  let acceptedFilesKey = state.DocketUploaderPageVariables.acceptedFilesKey;
  const index = acceptedFilesKey.indexOf(filePath);
  if (index > -1) {
    acceptedFilesKey.splice(index, 1);
  }
  delete state.DocketUploaderPageVariables.parsedData[filePath];
};

export const uploadDocket = (state) => {
  state.DocketUploaderPageVariables.uploadedDocket.docketKey = [
    ...state.DocketUploaderPageVariables.uploadedDocket.docketKey,
    ...state.DocketUploaderPageVariables.acceptedFilesKey,
  ];
  state.DocketUploaderPageVariables.uploadedDocket.docketData = {
    ...state.DocketUploaderPageVariables.uploadedDocket.docketData,
    ...state.DocketUploaderPageVariables.parsedData,
  };
  state.DocketUploaderPageVariables.acceptedFilesKey = [];
  state.DocketUploaderPageVariables.parsedData = {};
};

export const updateSignHistoryForm = (state, actionsAndParameters) => {
  const { name, value } = actionsAndParameters.payload;
  state.SignatureHistoryPageVariables[name] = value;
  checkSignForm(state);
};

export const checkJailEmailHistoryForm = (state) => {
  let jailEmailForm = state.JailEmailHistoryVariables;
  if (
    jailEmailForm.formData.caseNbr ||
    jailEmailForm.formData.startDate ||
    jailEmailForm.formData.endDate ||
    jailEmailForm.formData.emailPurpose !== null
  ) {
    jailEmailForm.formEnabled = true;
  } else {
    jailEmailForm.formEnabled = false;
  }
};

export const updateJailEmailHistoryForm = (state, actionsAndParameters) => {
  const { name, value } = actionsAndParameters.payload;
  state.JailEmailHistoryVariables.formData[name] = value;
  checkJailEmailHistoryForm(state);
};

export const resendJailEmail = (state, actionsAndParameters) => {
  const id = actionsAndParameters.payload;
  let jailEmails = state.JailEmailHistoryVariables.jailEmails;
  state.ModalDetailsPageVariables.modalDetails.runStatus = "STARTED";
  for (let i = 0; i < jailEmails.length; i++) {
    if (jailEmails[i].id === id) {
      jailEmails[i]["runStatus"] = "STARTED";
    }
  }
};

export const updateJailEmailDateForm = (state, actionsAndParameters) => {
  const dateString = actionsAndParameters.payload.dateString;
  state.JailEmailHistoryVariables.formData.startDate = dateString[0];
  state.JailEmailHistoryVariables.formData.endDate = dateString[1];
  checkJailEmailHistoryForm(state);
};

export const submitJailEmailHistoryForm = (state, actionsAndParameters) => {
  state.CircularProgressVariables.isLoading = false;
  state.JailEmailHistoryVariables.jailEmails =
    actionsAndParameters.payload.slice(0);
};

export const checkSignForm = (state) => {
  let signHistoryVar = state.SignatureHistoryPageVariables;
  if (
    signHistoryVar.caseNbr ||
    signHistoryVar.startDate ||
    signHistoryVar.endDate ||
    signHistoryVar.firstName ||
    signHistoryVar.middleName ||
    signHistoryVar.lastName
  ) {
    signHistoryVar.formEnabled = true;
  } else {
    signHistoryVar.formEnabled = false;
  }
};

export const updateSignDateForm = (state, actionsAndParameters) => {
  const dateString = actionsAndParameters.payload.dateString;
  state.SignatureHistoryPageVariables.startDate = dateString[0];
  state.SignatureHistoryPageVariables.endDate = dateString[1];
  checkSignForm(state);
};

export const checkDocketForm = (state) => {
  let docketHistoryVar = state.DocketHistoryPageVariables;
  if (
    docketHistoryVar.caseNbr ||
    docketHistoryVar.startDate ||
    docketHistoryVar.endDate ||
    docketHistoryVar.firstName ||
    docketHistoryVar.middleName ||
    docketHistoryVar.lastName
  ) {
    docketHistoryVar.formEnabled = true;
  } else {
    docketHistoryVar.formEnabled = false;
  }
};

export const updateDocketForm = (state, actionsAndParameters) => {
  const { name, value } = actionsAndParameters.payload;
  state.DocketHistoryPageVariables[name] = value;
  checkDocketForm(state);
};

export const updateDocketDateForm = (state, actionsAndParameters) => {
  const dateString = actionsAndParameters.payload.dateString;
  state.DocketHistoryPageVariables.startDate = dateString[0];
  state.DocketHistoryPageVariables.endDate = dateString[1];
  checkDocketForm(state);
};

export const submitDocketForm = (state, actionsAndParameters) => {
  state.CircularProgressVariables.isLoading = false;
  state.DocketHistoryPageVariables.dockets =
    actionsAndParameters.payload.slice(0);
};

export const submitSignHistoryForm = (state, actionsAndParameters) => {
  state.CircularProgressVariables.isLoading = false;
  state.SignatureHistoryPageVariables.signaturesData =
    actionsAndParameters.payload.slice(0);
};

export const toggleCircularLoading = (state) => {
  state.CircularProgressVariables.isLoading = true;
};

export const openDocumentTypeModal = (state) => {
  state.DocumentTypeMaintenanceVariables.showModal = true;
};

export const openSignPartyModal = (state) => {
  state.SignaturePartyMaintenanceVariables.showModal = true;
};

export const openDocSignModal = (state) => {
  state.DocumentSignatureMaintenanceVariables.showModal = true;
};

export const openJailEmailModal = (state) => {
  state.JailEmailMaintenanceVariables.showModal = true;
};

export const openJailEmailDocModal = (state) => {
  state.JailEmailDocumentMaintenanceVariables.showModal = true;
};

export const openUpdateDocTypeModal = (state, actionsAndParameters) => {
  const { id, documentTypeID, documentTypeDesc, documentTypeName } =
    actionsAndParameters.payload;
  state.DocumentTypeMaintenanceVariables.showModal = true;
  state.DocumentTypeMaintenanceVariables.addDocumentType.id = id;
  state.DocumentTypeMaintenanceVariables.addDocumentType.documentTypeID =
    documentTypeID;
  state.DocumentTypeMaintenanceVariables.addDocumentType.documentTypeDesc =
    documentTypeDesc;
  state.DocumentTypeMaintenanceVariables.addDocumentType.documentTypeName =
    documentTypeName;
};

export const openUpdatePartySignModal = (state, actionsAndParameters) => {
  const { id, signaturePartyName } = actionsAndParameters.payload;
  state.SignaturePartyMaintenanceVariables.showModal = true;
  state.SignaturePartyMaintenanceVariables.addSignatureParty.id = id;
  state.SignaturePartyMaintenanceVariables.addSignatureParty.signaturePartyName =
    signaturePartyName;
};

export const openUpdateJailEmailModal = (state, actionsAndParameters) => {
  const { id, emailID, subjectPurpose, emailSubject, emailBody } =
    actionsAndParameters.payload;
  state.JailEmailMaintenanceVariables.showModal = true;
  state.JailEmailMaintenanceVariables.addEmailPurpose.id = id;
  state.JailEmailMaintenanceVariables.addEmailPurpose.emailID = emailID;
  state.JailEmailMaintenanceVariables.addEmailPurpose.subjectPurpose =
    subjectPurpose;
  state.JailEmailMaintenanceVariables.addEmailPurpose.emailSubject =
    emailSubject;
  state.JailEmailMaintenanceVariables.addEmailPurpose.emailBody = emailBody;
};

export const openUpdateJailEmailDocModal = (state, actionsAndParameters) => {
  const { id, subjectPurpose, docs } = actionsAndParameters.payload;
  state.JailEmailDocumentMaintenanceVariables.showModal = true;
  state.JailEmailDocumentMaintenanceVariables.addEmailPurposeDoc.id = id;
  state.JailEmailDocumentMaintenanceVariables.addEmailPurposeDoc.docs =
    docs.slice(0);
  state.JailEmailDocumentMaintenanceVariables.addEmailPurposeDoc.subjectPurpose =
    subjectPurpose;
};

export const openUpdateDocSignModal = (state, actionsAndParameters) => {
  const {
    id,
    pageNo,
    documentType,
    signatureParty,
    x0,
    x1,
    y0,
    y1,
    isRequired,
  } = actionsAndParameters.payload;
  state.DocumentSignatureMaintenanceVariables.showModal = true;
  state.DocumentSignatureMaintenanceVariables.addDocSign.id = id;
  state.DocumentSignatureMaintenanceVariables.addDocSign.documentType =
    documentType;
  state.DocumentSignatureMaintenanceVariables.addDocSign.signatureParty =
    signatureParty;
  state.DocumentSignatureMaintenanceVariables.addDocSign.x0 = x0;
  state.DocumentSignatureMaintenanceVariables.addDocSign.x1 = x1;
  state.DocumentSignatureMaintenanceVariables.addDocSign.y0 = y0;
  state.DocumentSignatureMaintenanceVariables.addDocSign.y1 = y1;
  state.DocumentSignatureMaintenanceVariables.addDocSign.pageNo = pageNo;
  state.DocumentSignatureMaintenanceVariables.addDocSign.isRequired =
    isRequired;
};

export const closeDocumentTypeModal = (state) => {
  state.DocumentTypeMaintenanceVariables.showModal = false;
  state.DocumentTypeMaintenanceVariables.addDocumentType.id = "";
  state.DocumentTypeMaintenanceVariables.addDocumentType.documentTypeID = "";
  state.DocumentTypeMaintenanceVariables.addDocumentType.documentTypeName = "";
  state.DocumentTypeMaintenanceVariables.addDocumentType.documentTypeDesc = "";
  state.DocumentTypeMaintenanceVariables.formEnabled = false;
};

export const closeSignPartyModal = (state) => {
  state.SignaturePartyMaintenanceVariables.showModal = false;
  state.SignaturePartyMaintenanceVariables.addSignatureParty.id = "";
  state.SignaturePartyMaintenanceVariables.addSignatureParty.signaturePartyName =
    "";
  state.SignaturePartyMaintenanceVariables.formEnabled = false;
};

export const closeJailEmailModal = (state) => {
  state.JailEmailMaintenanceVariables.showModal = false;
  state.JailEmailMaintenanceVariables.addEmailPurpose.id = "";
  state.JailEmailMaintenanceVariables.addEmailPurpose.emailID = "";
  state.JailEmailMaintenanceVariables.addEmailPurpose.subjectPurpose = null;
  state.JailEmailMaintenanceVariables.addEmailPurpose.emailSubject = "";
  state.JailEmailMaintenanceVariables.addEmailPurpose.emailBody = "";
  state.JailEmailMaintenanceVariables.formEnabled = false;
};

export const closeJailEmailDocModal = (state) => {
  state.JailEmailDocumentMaintenanceVariables.showModal = false;
  state.JailEmailDocumentMaintenanceVariables.addEmailPurposeDoc.id = "";
  state.JailEmailDocumentMaintenanceVariables.addEmailPurposeDoc.subjectPurpose =
    "";
  state.JailEmailDocumentMaintenanceVariables.addEmailPurposeDoc.docs = [];
  state.JailEmailDocumentMaintenanceVariables.formEnabled = false;
};

export const closeDocSignModal = (state) => {
  state.DocumentSignatureMaintenanceVariables.showModal = false;
  state.DocumentSignatureMaintenanceVariables.addDocSign.id = "";
  state.DocumentSignatureMaintenanceVariables.addDocSign.documentType = null;
  state.DocumentSignatureMaintenanceVariables.addDocSign.signatureParty = null;
  state.DocumentSignatureMaintenanceVariables.addDocSign.x0 = "";
  state.DocumentSignatureMaintenanceVariables.addDocSign.y0 = "";
  state.DocumentSignatureMaintenanceVariables.addDocSign.x1 = "";
  state.DocumentSignatureMaintenanceVariables.addDocSign.y1 = "";
  state.DocumentSignatureMaintenanceVariables.addDocSign.pageNo = "";
  state.DocumentSignatureMaintenanceVariables.addDocSign.isRequired = false;
  state.DocumentSignatureMaintenanceVariables.formEnabled = false;
};

export const checkDocTypeForm = (state, addType, page) => {
  state[page].formEnabled =
    state[page][addType].documentTypeID.trim() !== "" &&
    state[page][addType].documentTypeName.trim() !== "" &&
    state[page][addType].documentTypeDesc.trim() !== "";
};

export const checkSignPartyForm = (state, addType, page) => {
  state[page].formEnabled =
    state[page][addType].signaturePartyName.trim() !== "";
};

export const checkDocSignForm = (state, addType, page) => {
  state[page].formEnabled =
    state[page][addType].documentType !== null &&
    state[page][addType].signatureParty !== null &&
    state[page][addType].x0.trim() !== "" &&
    state[page][addType].x1.trim() !== "" &&
    state[page][addType].y0.trim() !== "" &&
    state[page][addType].y1.trim() !== "" &&
    state[page][addType].pageNo.trim() !== "";
};

export const checkJailEmailDocForm = (state, addType, page) => {
  state[page].formEnabled =
    state[page][addType].subjectPurpose.trim() !== "" &&
    state[page][addType].docs.length > 0;
};

export const checkJailEmailForm = (state, addType, page) => {
  state[page].formEnabled =
    state[page][addType].subjectPurpose !== null &&
    state[page][addType].emailID.trim() !== "" &&
    state[page][addType].emailBody.trim() !== "";
};

export const updateMaintenanceForm = (state, actionsAndParameters) => {
  const { page, addType, name, value } = actionsAndParameters.payload;
  state[page][addType][name] = value;
  switch (page) {
    case "DocumentTypeMaintenanceVariables":
      checkDocTypeForm(state, addType, page);
      break;
    case "SignaturePartyMaintenanceVariables":
      checkSignPartyForm(state, addType, page);
      break;
    // case "DocumentSignatureMaintenanceVariables":
    //   checkDocSignForm(state, addType, page);
    //   break;
    case "JailEmailDocumentMaintenanceVariables":
      checkJailEmailDocForm(state, addType, page);
      break;
    case "JailEmailMaintenanceVariables":
      checkJailEmailForm(state, addType, page);
      break;
    default:
      break;
  }
};

export const updateMaintenanceCordForm = (state, actionsAndParameters) => {
  const { page, addType, name, value } = actionsAndParameters.payload;
  if (value !== "") {
    state[page][addType][name] = parseInt(value);
  } else {
    state[page][addType][name] = value;
  }

  switch (page) {
    case "DocumentTypeMaintenanceVariables":
      checkDocTypeForm(state, addType, page);
      break;
    case "SignaturePartyMaintenanceVariables":
      checkSignPartyForm(state, addType, page);
      break;
    // case "DocumentSignatureMaintenanceVariables":
    //   checkDocSignForm(state, addType, page);
    //   break;
    case "JailEmailDocumentMaintenanceVariables":
      checkJailEmailDocForm();
      break;
    case "JailEmailMaintenanceVariables":
      checkJailEmailForm();
      break;
    default:
      break;
  }
};

export const handleSwitchChange = (state, actionsAndParameters) => {
  const { checked, page, addType, addTypeVar, addTypeData } =
    actionsAndParameters.payload;
  if (page === "DocumentSignatureMaintenanceVariables") {
    state[page][addType].coordinates[addTypeVar][addTypeData] = checked;
  } else {
    state[page][addType][addTypeData] = checked;
  }
};

export const deleteDocSignCoordinate = (state, actionsAndParameters) => {
  let coordinates =
    state.DocumentSignatureMaintenanceVariables.addDocSign.coordinates;
  state.DocumentSignatureMaintenanceVariables.addDocSign.deletedIDs.push(
    coordinates[actionsAndParameters.payload]?.id
  );
  let newCoordinates = Object.keys(coordinates)
    .filter((key) => key !== actionsAndParameters.payload)
    .reduce((acc, key) => {
      acc[key] = coordinates[key];
      return acc;
    }, {});
  state.DocumentSignatureMaintenanceVariables.addDocSign.coordinates =
    newCoordinates;
};

export const updateDocPartyDetails = (state, actionsAndParameters) => {
  const { documentType, signatureParty, signatureHintText } =
    actionsAndParameters.payload;
  let signaturePartyName = signatureParty?.signaturePartyName;
  let documentTypeID = documentType?.documentTypeID;
  let boundaryDetails =
    state.DocumentSignatureMaintenanceVariables.addDocSign.boundaryDetails;
  let coordinates =
    state.DocumentSignatureMaintenanceVariables.addDocSign.coordinates;
  if (documentTypeID === undefined || documentTypeID === null) {
    toast.error("Please select Document type", { id: 1 });
    return;
  }
  if (signaturePartyName === undefined || signaturePartyName === null) {
    toast.error("Please select Signature Party name", { id: 1 });
    return;
  }
  if (
    signatureHintText === undefined ||
    signatureHintText === null ||
    signatureHintText === ""
  ) {
    toast.error("Please add Signature Party hint text", { id: 1 });
    return;
  }
  if (coordinates.hasOwnProperty(signaturePartyName)) {
    coordinates[signaturePartyName].signatureHintText =
      signatureHintText.trim();
    if (boundaryDetails === null || boundaryDetails === undefined) {
    } else {
      coordinates[signaturePartyName].boundary = boundaryDetails?.boundary;
      coordinates[signaturePartyName].page = boundaryDetails?.page;
    }
    toast.success("Details have been updated", { id: 1 });
  } else {
    if (boundaryDetails === null || boundaryDetails === undefined) {
      toast.error("Set coordinates first", { id: 1 });
      return;
    } else {
      coordinates[signaturePartyName] = {};
      coordinates[signaturePartyName].isRequired =
        state.DocumentSignatureMaintenanceVariables.addDocSign.isRequired;
      coordinates[signaturePartyName].isInitial =
        state.DocumentSignatureMaintenanceVariables.addDocSign.isInitial;
      coordinates[signaturePartyName].documentTypeID = documentType?.id;
      coordinates[signaturePartyName].signaturePartyID = signatureParty?.id;
      coordinates[signaturePartyName].id = 0;
      coordinates[signaturePartyName].signatureHintText =
        signatureHintText.trim();
      coordinates[signaturePartyName].boundary = boundaryDetails?.boundary;
      coordinates[signaturePartyName].page = boundaryDetails?.page;
      toast.success("Details have been added", { id: 1 });
    }
  }
};

export const setBoundary = (state, actionsAndParameters) => {
  state.DocumentSignatureMaintenanceVariables.addDocSign.boundaryDetails =
    actionsAndParameters.payload;
};

export const markCoordinates = (state, actionsAndParameters) => {
  let coordinates =
    state.DocumentSignatureMaintenanceVariables.addDocSign.coordinates;
  let signaturePartyName =
    state.DocumentSignatureMaintenanceVariables.addDocSign.signatureParty
      ?.signaturePartyName;
  let documentTypeID =
    state.DocumentSignatureMaintenanceVariables.addDocSign.documentType
      ?.documentTypeID;
  let signatureHintText =
    state.DocumentSignatureMaintenanceVariables.addDocSign?.signatureHintText;
  if (documentTypeID === undefined || documentTypeID === null) {
    toast.error("Please select Document type", { id: 1 });
    return;
  }
  if (signaturePartyName === undefined || signaturePartyName === null) {
    toast.error("Please select Signature Party name", { id: 1 });
    return;
  }
  if (
    signatureHintText === undefined ||
    signatureHintText === null ||
    signatureHintText === ""
  ) {
    toast.error("Please add Signature Party hint text", { id: 1 });
    return;
  }
  if (actionsAndParameters.payload !== null) {
    if (coordinates.hasOwnProperty(signaturePartyName)) {
      coordinates[signaturePartyName].boundary =
        actionsAndParameters.payload.boundary;
      coordinates[signaturePartyName].page = actionsAndParameters.payload.page;
      coordinates[signaturePartyName].signatureHintText =
        state.DocumentSignatureMaintenanceVariables.addDocSign.signatureHintText;
    } else {
      coordinates[signaturePartyName] = {};
      coordinates[signaturePartyName] = actionsAndParameters.payload;
      coordinates[signaturePartyName].isRequired =
        state.DocumentSignatureMaintenanceVariables.addDocSign.isRequired;
      coordinates[signaturePartyName].documentTypeID =
        state.DocumentSignatureMaintenanceVariables.addDocSign.documentType?.id;
      coordinates[signaturePartyName].signaturePartyID =
        state.DocumentSignatureMaintenanceVariables.addDocSign.signatureParty?.id;
      coordinates[signaturePartyName].id = 0;
      coordinates[signaturePartyName].signatureHintText =
        state.DocumentSignatureMaintenanceVariables.addDocSign.signatureHintText;
    }
  }
};

export const toggleCheckBox = (state, actionsAndParameters) => {
  const { page, addType } = actionsAndParameters.payload;
  state[page][addType].isRequired = !state[page][addType].isRequired;
};

export const submitDocumentType = (state) => {
  state.DocumentTypeMaintenanceVariables.showModal = false;
  state.DocumentTypeMaintenanceVariables.addDocumentType.id = "";
  state.DocumentTypeMaintenanceVariables.addDocumentType.documentTypeID = "";
  state.DocumentTypeMaintenanceVariables.addDocumentType.documentTypeName = "";
  state.DocumentTypeMaintenanceVariables.addDocumentType.documentTypeDesc = "";
  state.DocumentTypeMaintenanceVariables.formEnabled = false;
};

export const submitJailEmailPurpose = (state) => {
  state.JailEmailMaintenanceVariables.showModal = false;
  state.JailEmailMaintenanceVariables.addEmailPurpose.id = "";
  state.JailEmailMaintenanceVariables.addEmailPurpose.emailID = "";
  state.JailEmailMaintenanceVariables.addEmailPurpose.subjectPurpose = null;
  state.JailEmailMaintenanceVariables.addEmailPurpose.emailSubject = "";
  state.JailEmailMaintenanceVariables.addEmailPurpose.emailBody = "";
  state.JailEmailMaintenanceVariables.formEnabled = false;
};

export const submitJailEmailDocForm = (state) => {
  state.JailEmailDocumentMaintenanceVariables.showModal = false;
  state.JailEmailDocumentMaintenanceVariables.addEmailPurposeDoc.id = "";
  state.JailEmailDocumentMaintenanceVariables.addEmailPurposeDoc.subjectPurpose =
    "";
  state.JailEmailDocumentMaintenanceVariables.addEmailPurposeDoc.docs = [];
  state.JailEmailDocumentMaintenanceVariables.formEnabled = false;
};

export const submitSignatureParty = (state) => {
  state.SignaturePartyMaintenanceVariables.showModal = false;
  state.SignaturePartyMaintenanceVariables.addSignatureParty.id = "";
  state.SignaturePartyMaintenanceVariables.addSignatureParty.signaturePartyName =
    "";
  state.SignaturePartyMaintenanceVariables.formEnabled = false;
};

export const submitDocSignForm = (state) => {
  state.DocumentSignatureMaintenanceVariables.addDocSign.id = "";
  state.DocumentSignatureMaintenanceVariables.addDocSign.documentType = null;
  state.DocumentSignatureMaintenanceVariables.addDocSign.signatureParty = null;
  state.DocumentSignatureMaintenanceVariables.addDocSign.signatureHintText = "";
  state.DocumentSignatureMaintenanceVariables.addDocSign.fileUrl = null;
  state.DocumentSignatureMaintenanceVariables.addDocSign.coordinates = {};
  state.DocumentSignatureMaintenanceVariables.addDocSign.deletedIDs = [];
  state.DocumentSignatureMaintenanceVariables.addDocSign.boundaryDetails = null;
};

export const loadDocTypeData = (state, actionsAndParameters) => {
  state.DocumentTypeMaintenanceVariables.documentTypes =
    actionsAndParameters.payload.slice(0);
};

export const loadSignPartyData = (state, actionsAndParameters) => {
  state.SignaturePartyMaintenanceVariables.signatureParties =
    actionsAndParameters.payload.slice(0);
};

export const loadDocSignData = (state, actionsAndParameters) => {
  state.DocumentSignatureMaintenanceVariables.docSignatures =
    actionsAndParameters.payload.slice(0);
};

export const loadDocSignCoordData = (state, actionsAndParameters) => {
  state.DocumentSignatureMaintenanceVariables.docSignatures =
    actionsAndParameters.payload;
};

export const loadJailEmailData = (state, actionsAndParameters) => {
  state.JailEmailMaintenanceVariables.jailEmails =
    actionsAndParameters.payload.slice(0);
};

export const loadJailEmailDocData = (state, actionsAndParameters) => {
  state.JailEmailDocumentMaintenanceVariables.jailEmailDocs =
    actionsAndParameters.payload.slice(0);
};

export const deleteDocType = (state, actionsAndParameters) => {};
