import { Component } from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router";
import "./App.css";
import * as api from "./Api/ApiCallbacks";

import Home from "./Components/global/Home";
import LeftSidebar from "./Components/global/LeftSidebar";
import Header from "./Components/global/Header";
import DocketUploader from "./Components/global/DocketUploader";
import SignatureHistory from "./Components/global/SignatureHistory";
import JailEmailHistory from "./Components/global/JailEmailHistory";
import Help from "./Components/global/Help";
import DocketHistory from "./Components/global/DocketHistory";
import Maintenance from "./Components/global/Maintenance/Maintenance";
import Login from "./Components/global/Login";
import { ProtectedAdminLayout } from "./Components/admin/ProtectedAdminLayout";
import AppDownloader from "./Components/global/AppDownloader";
import DocSignCoordinates from "./Components/global/Maintenance/DocSignCoordinates";
import { ProtectedUserLayout } from "./Components/users/ProtectedUserLayout";
import ForgotPassword from "./Components/global/ForgotPassword";
import { Toaster } from "react-hot-toast";
import SetNewPassword from "./Components/global/SetNewPassword";

class App extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.isAuthenticated &&
      prevProps.apiLocationLoaded !== this.props.apiLocationLoaded
    ) {
    }
  }
  componentDidMount() {
    const { setInitLoad, loadApiSetting, apiLocationLoaded } = this.props;
    if (apiLocationLoaded) {
    } else {
      loadApiSetting();
    }
    setInitLoad(false);
  }
  render() {
    return (
      <>
        {this.props.isAuthenticated ? <Header /> : <></>}
        <div style={{ display: "flex", width: "100%" }}>
          <Toaster
            position="top-center"
            toastOptions={{
              duration: 3000,
              style: {
                marginTop: 80,
                background: "#162B42",
                color: "#fff",
              },
            }}
          />
          {this.props.isAuthenticated ? <LeftSidebar /> : <></>}
          <div className="main-container">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/forgot_password" element={<ForgotPassword />} />
              <Route path="set_new_password" element={<SetNewPassword />} />
              {/* <Route path="/signup" element={<Signup />} /> */}
              <Route path="/" element={<ProtectedUserLayout />}>
                <Route path="home" element={<Home />} />
                <Route path="docket_uploader" element={<DocketUploader />} />
                <Route path="docket_history" element={<DocketHistory />} />
                <Route path="e-signature" element={<SignatureHistory />} />
                <Route
                  path="jail_email_history"
                  element={<JailEmailHistory />}
                />
                <Route path="app_download" element={<AppDownloader />} />
                <Route path="help" element={<Help />} />
              </Route>
              <Route path="/admin" element={<ProtectedAdminLayout />}>
                <Route path="maintenance" element={<Maintenance />} />
                <Route
                  path="sign_maintenance"
                  element={<DocSignCoordinates />}
                />
              </Route>
            </Routes>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  initLoad: state.initLoad,
  apiLocationLoaded: state.apiLocationLoaded,
  isAuthenticated: state.UserDataVariables.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setInitLoad: (val) => dispatch({ type: "SET_INIT_LOAD", value: val }),
    loadApiSetting: () => api.loadApiSetting(dispatch),
    loadDocTypeData: () => api.loadDocTypeData(dispatch),
    loadSignPartyData: () => api.loadSignPartyData(dispatch),
    loadDocSignData: () => api.loadDocSignData(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
