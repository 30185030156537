import React, { useEffect, useRef, useState } from "react";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import { useDispatch } from "react-redux";

import { Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

pdfjsLib.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.9.179/pdf.worker.min.js";

const PdfViewer = (props) => {
  const dispatch = useDispatch();
  const pdfCanvasRef = useRef(null);
  const drawingCanvasRef = useRef(null);
  const pdfContainerRef = useRef(null);
  const [totalPages, setTotalPages] = useState(null);
  const [pdfInstance, setPdfInstance] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentMousePosition, setCurrentMousePosition] = useState({
    x: 0,
    y: 0,
  });
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [isMouseDown, setMouseDown] = useState(false);
  const [activeDrawing, setActiveDrawing] = useState();

  const onMouseMove = (event) => {
    let boundingRect = drawingCanvasRef.current.getBoundingClientRect();
    setCurrentMousePosition({
      x: event.clientX - boundingRect.left,
      y: event.clientY - boundingRect.top,
    });
  };
  const triggerMouseDown = (event) => {
    if (!drawingCanvasRef.current) return;
    let boundingRect = drawingCanvasRef.current.getBoundingClientRect();
    if (!isMouseDown) {
      setMouseDown(true);
      //check is there active drawing
      if (!activeDrawing)
        setActiveDrawing({
          start: {
            x: event.clientX - boundingRect.left,
            y: event.clientY - boundingRect.top,
          },
          completed: false,
        });
    }
    setCurrentMousePosition({
      x: event.clientX - boundingRect.left,
      y: event.clientY - boundingRect.top,
    });
  };
  const triggerMouseUp = () => {
    setMouseDown(false);
    setActiveDrawing(undefined);
    draw();
  };

  const draw = () => {
    const canvas = drawingCanvasRef.current;
    const context = canvas.getContext("2d", { willReadFrequently: true });
    if (context && activeDrawing?.start) {
      context.putImageData(
        context.getImageData(
          0,
          0,
          context.canvas.clientWidth,
          context.canvas.clientHeight
        ),
        0,
        0
      );
      context.save();

      context.clearRect(0, 0, canvas.width, canvas.height);
      context.beginPath();

      const startX =
        currentMousePosition.x < activeDrawing.start.x
          ? currentMousePosition.x
          : activeDrawing.start.x;
      const startY =
        currentMousePosition.y < activeDrawing.start.y
          ? currentMousePosition.y
          : activeDrawing.start.y;
      const widthX = Math.abs(activeDrawing.start.x - currentMousePosition.x);
      const widthY = Math.abs(activeDrawing.start.y - currentMousePosition.y);
      // context.beginPath();
      context.strokeStyle = "red";
      context.lineWidth = 2;
      context.rect(startX, startY, widthX, widthY);
      dispatch({
        type: "SET_BOUNDARY",
        payload: {
          boundary: {
            x0: startX,
            x1: startX + widthX,
            y0: height - startY,
            y1: height - startY - widthY,
          },
          page: currentPage,
        },
      });
      context.stroke();
      context.restore();
    }
  };

  useEffect(() => {
    if (!isMouseDown) return;
    if (!drawingCanvasRef.current) {
      return;
    }
    draw();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMouseDown, currentMousePosition]);

  useEffect(() => {
    if (!drawingCanvasRef.current) {
      return;
    }
    const canvas = drawingCanvasRef.current;
    canvas.addEventListener("mouseup", triggerMouseUp);
    canvas.addEventListener("mousedown", triggerMouseDown);
    canvas.addEventListener("mousemove", onMouseMove);
    return () => {
      canvas.removeEventListener("mouseup", triggerMouseUp);
      canvas.removeEventListener("mouseleave", triggerMouseDown);
      canvas.removeEventListener("mousemove", onMouseMove);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadPdf = async () => {
      try {
        let options = {
          url: props.pdfUrl,
          verbosity: 0,
        };
        const loadingTask = pdfjsLib.getDocument(options);
        const pdf = await loadingTask.promise;
        setTotalPages(pdf.numPages);
        setPdfInstance(pdf);
      } catch (error) {
        console.error("Error loading PDF:", error);
      }
    };

    loadPdf();
  }, [props.pdfUrl]);

  const clearCanvas = () => {
    const drawingCanvas = drawingCanvasRef.current;
    drawingCanvas.getContext("2d").clearRect(0, 0, width, height);
    dispatch({
      type: "SET_BOUNDARY",
      payload: null,
    });
  };

  useEffect(() => {
    if (!pdfInstance) return;

    const loadPage = async () => {
      const page = await pdfInstance.getPage(currentPage);
      const scale = 1;
      const viewport = page.getViewport({ scale });
      const pdfCanvas = pdfCanvasRef.current;
      if (pdfCanvas) {
        pdfCanvas
          .getContext("2d")
          .clearRect(0, 0, pdfCanvas.width, pdfCanvas.height);
        pdfCanvas.width = 0;
        pdfCanvas.height = 0;
      }
      // Create a new PDF canvas
      const newPdfCanvas = document.createElement("canvas");
      newPdfCanvas.className = "pdf-canvas";
      pdfCanvasRef.current = newPdfCanvas;
      pdfContainerRef.current.appendChild(newPdfCanvas);
      const context = newPdfCanvas.getContext("2d");
      newPdfCanvas.height = viewport.height;
      newPdfCanvas.width = viewport.width;
      setWidth(viewport.width);
      setHeight(viewport.height);
      const drawingCanvas = drawingCanvasRef.current;
      drawingCanvas.height = viewport.height;
      drawingCanvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport,
      };
      page.render(renderContext);
    };

    loadPage();
  }, [pdfInstance, currentPage]);

  return (
    <div>
      <div className="pdf-viewer-button-container">
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={clearCanvas}
          className="buttons-section-pdf-viewer"
        >
          Clear Canvas
        </Button>
        {/* <Button
          variant="contained"
          size="small"
          disabled={boundary === null}
          className="buttons-section-pdf-viewer"
          onClick={() => {
            dispatch({
              type: "MARK_COORDINATES",
              payload: {
                boundary,
                page: currentPage,
              },
            });
          }}
        >
          Mark Coordinates
        </Button> */}
        <Button
          variant="contained"
          size="small"
          disabled={currentPage === 1}
          onClick={() => {
            setCurrentPage((prevPage) => prevPage - 1);
            dispatch({
              type: "SET_BOUNDARY",
              payload: null,
            });
          }}
          className="buttons-section-pdf-viewer"
          startIcon={<ArrowBackIosIcon />}
        >
          Previous Page
        </Button>
        <Button
          size="small"
          disabled={currentPage === totalPages}
          variant="contained"
          className="buttons-section-pdf-viewer"
          onClick={() => {
            setCurrentPage((prevPage) => prevPage + 1);
            dispatch({
              type: "SET_BOUNDARY",
              payload: null,
            });
          }}
          endIcon={<ArrowForwardIosIcon />}
        >
          Next Page
        </Button>
      </div>
      <div className="pdf-viewer-canvas-container">
        <div className="pdf-viewer-drawing-canvas">
          <canvas ref={drawingCanvasRef} />
        </div>
        <div className="pdf-viewer-page-canvas" ref={pdfContainerRef}>
          <canvas ref={pdfCanvasRef} />
        </div>
      </div>
    </div>
  );
};

export default PdfViewer;
